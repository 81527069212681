import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import './terms.css';

export default function TermsOfServices() {
  return (
    <>
      <main className="terms-services-main">
        <div className="terms-main-container">
          <p className="terms-update-container">Updated on 23 May, 2024</p>
          <h1 className="terms-of-services">Terms of Services</h1>
        </div>
        <section className="terms-services-content-container">
          <div className="explrar-privacy-policy-text">
            <p className="similar-font-size">
              Welcome to Explrar! These Terms of Service govern your use of our
              website, www.explrar.com, and our mobile application, provided by
              Explrar Pte. Ltd., a company registered in Singapore. By accessing
              or using our services, you agree to comply with these terms, so
              please read them carefully.
            </p>
            <p className="similar-design-p margin-top-20px">
              By accessing our website, you are agreeing to be bound by these
              terms of service, and agree that you are responsible for
              compliance with any applicable local laws.
            </p>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">Using Our Services</h2>
            <p className="similar-design-p">
              You are required to adhere to the policies provided within the
              services. Our services must be used only in a lawful manner and in
              compliance with applicable regulations. While anyone can access
              and use our services, certain features may require you to register
              an account.
            </p>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">Account Registration</h2>
            <p className="similar-design-p">
              You may create your own Explrar Account to access additional
              features and services. It is important to provide accurate and
              current information during the registration process. You are
              responsible for safeguarding your account and should notify us
              immediately of any unauthorized use of your account.
            </p>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">
              General Booking Terms (Apply to All Travel Products)
            </h2>
            <div className="similar-margin-div2">
              <h3 className="similar-design-h3">Details and Price</h3>
              <p className="similar-design-p">
                When booking with Explrar, you confirm that all details you
                provide, including name, ID details, contact details, payment
                details, and dates, are accurate and complete. If any
                discrepancies are found in the details provided due to an error
                on your part, we will assist in modifying them, subject to the
                approval of the Supplier. Changes in identity may not be
                permitted.
              </p>
              <p className="similar-design-p margin-top-20px">
                While we endeavor to maintain accurate prices, errors may occur.
                If an error in pricing is evident and should reasonably have
                been apparent to you, we reserve the right to either cancel the
                booking or amend the price, subject to your agreement. In such
                instances, we will contact you promptly.
              </p>
            </div>
            <div className="similar-margin-div2">
              <h3 className="similar-design-h3">Booking Process</h3>
              <p className="similar-design-p">
                Upon completion of your booking, you will receive a confirmation
                email. It is your responsibility to review the details of your
                booking confirmation and notify us immediately if any
                information is incorrect.
              </p>
              <p className="similar-design-p margin-top-20px">
                For ease of communication, each booking will be assigned a
                unique Explrar booking number. Please reference this number in
                any correspondence related to your booking.
              </p>
              <p className="similar-design-p margin-top-20px">
                While making your booking, you must not:
              </p>
              <div className="privacy-policy-list-item-container-2">
                <div className="privacy-policy-list-item">
                  <FiberManualRecordIcon
                    style={{
                      alignSelf: 'start',
                      marginTop: '10px',
                    }}
                    sx={{ fontSize: '6px', marginLeft: '12px' }}
                  />
                  <p className="similar-design-p">
                    Use a bank card or account that is invalid or that you are
                    not authorized to use.
                  </p>
                </div>
                <div className="privacy-policy-list-item">
                  <FiberManualRecordIcon
                    style={{ alignSelf: 'start', marginTop: '10px' }}
                    sx={{ fontSize: '6px', marginLeft: '12px' }}
                  />
                  <p className="similar-design-p">
                    Impersonate any person or misrepresent your identity or
                    affiliation with any person.
                  </p>
                </div>
                <div className="privacy-policy-list-item">
                  <FiberManualRecordIcon
                    sx={{ fontSize: '6px', marginLeft: '12px' }}
                  />
                  <p className="similar-design-p">
                    Violate any applicable laws, regulations, or regulatory
                    requirements.
                  </p>
                </div>
              </div>
              <p className="similar-design-p margin-top-20px">
                All bookings are subject to availability. We make continuous
                efforts to update our website; however, we cannot guarantee the
                availability of any Travel Products at the time of booking. If a
                Travel Product becomes unavailable after your booking, we will
                inform you as soon as possible.
              </p>
              <p className="similar-design-p margin-top-20px">
                We may require additional information or verifications before
                confirming your booking. Failure to provide necessary
                information or satisfactory verification may result in your
                booking being considered incomplete and subsequently canceled.
                We are not liable for any loss or cost incurred due to these
                circumstances.
              </p>
            </div>
            <div className="similar-margin-div2">
              <h3 className="similar-design-h3">Payment and Currency</h3>
              <p className="similar-design-p">
                The specific terms of payment for each Travel Product will be
                clarified during the booking process. You may be required to
                prepay online or pay at the time of service usage, such as at
                hotel check-in.
              </p>
              <p className="similar-design-p margin-top-20px">
                If payments are not made in accordance with the booking terms,
                we reserve the right to cancel your booking. This includes cases
                indicative of fraud or where the payment method fails.
              </p>
              <p className="similar-design-p margin-top-20px">
                Exchange rates are calculated in real-time for online
                prepayments. If paying in a currency different from the one
                shown on the payment page, your bank or payment provider may
                apply a different exchange rate, which you are responsible for
                verifying. Additionally, your provider may charge a fee for
                international transactions.
              </p>
              <p className="similar-design-p margin-top-20px">
                If you encounter any issues during the payment process, please
                do not attempt the payment again. Contact us to confirm whether
                your booking was successful using the contact information
                provided in section 1 of these terms.
              </p>
              <p className="similar-design-p margin-top-20px">
                Refunds, if applicable, will be processed to the original
                payment method unless agreed otherwise. The timing of the refund
                is dependent on the processing time of your payment provider.
              </p>
            </div>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">
              Privacy and Copyright Protection
            </h2>
            <p className="similar-design-p">
              Our privacy policies describe how we handle your personal data and
              protect your privacy when you use our services. By using Explrar,
              you agree that we can process your information in accordance with
              these policies. Our services may allow you to upload or submit
              content. You retain all rights to any intellectual property you
              post, but you grant Explrar a license to use, host, store,
              reproduce, and publicly display that content as part of our
              services.
            </p>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">Software</h2>
            <p className="similar-design-p">
              Explrar grants you a non-exclusive, non-transferable license to
              use the software provided as part of the services. This license is
              solely for the purpose of enabling you to use the services
              provided by Explrar in the manner permitted by these terms.
            </p>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">
              Modifying and Terminating Our Services
            </h2>
            <p className="similar-design-p">
              Explrar continuously improves and changes its services. We may add
              or remove functionalities or features, and we reserve the right to
              suspend or discontinue a service at any time. You may discontinue
              your use of our services at any time.
            </p>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">Warranties and Disclaimers</h2>
            <p className="similar-design-p">
              We aim to provide our services with a reasonable level of skill
              and care. However, other than as expressly set out in these terms,
              Explrar does not make any specific promises about the services.
            </p>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">Liability</h2>
            <p className="similar-design-p">
              To the extent permitted by law, Explrar will not be liable for any
              indirect, special, incidental, punitive, exemplary, or
              consequential damages, or any loss of use, data, business, or
              profits, regardless of legal theory.
            </p>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">About These Terms</h2>
            <p className="similar-design-p">
              We may modify these terms to reflect changes to our services or
              the law by posting updated terms on this page. We recommend
              checking the terms periodically for changes. Notice of
              modifications will be provided at least fourteen days before they
              become effective, except when changes are made for legal reasons,
              which will be effective immediately.
            </p>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">Contact Us</h2>
            <p className="similar-design-p">
              For questions or concerns about these Terms of Service, please
              contact us at{' '}
              <a href="mailto:support@explrar.com">support@explrar.com</a>
            </p>
            <p className="similar-design-p margin-top-20px">
              Thank you for choosing Explrar. We hope you enjoy your journey
              with us.
            </p>
          </div>
        </section>
      </main>
    </>
  );
}
