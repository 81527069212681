import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  userLoginSuccess,
  userLoginFailure,
  userVerifyOTPSuccess,
  userVerifyOTPFailure,
  userResendOTPSuccess,
  userResendOTPFailure,
  userSignupSuccess,
  userSignupFailure,
  userPasswordResetSuccess,
  userPasswordResetFailure,
  userPasswordUpdateSuccess,
  userPasswordUpdateFailure,
  getTopDestinationSuccess,
  getTopDestinationFailure,
  getPopularAttractionCountriesSuccess,
  getPopularAttractionCountriesFailure,
  getPopularAttractionSuccess,
  getPopularAttractionFailure,
  getBackgroungImagesListSuccess,
  getBackgroungImagesListFailure,
  getUserProfileSuccess,
  getUserProfileFailure,
  getCitiesListSuccess,
  getCitiesListFailure,
  generateTripSuccess,
  generateTripFailure,
  getFlightSuccess,
  getFlightFailure,
  getFlightPopularRoutesSuccess,
  getFlightPopularRoutesFailure,
  getFlightDataSuccess,
  getFlightDataFailure,
  getTopCitiesSuccess,
  getTopCitiesFailure,
  getHotelAndCitiesSuccess,
  getHotelAndCitiesFailure,
  getHotelListSuccess,
  getHotelListFailure,
  getLocationsFailure,
  getLocationsSuccess,
  getHotelByCitySuccess,
  getHotelByCityFailure,
  getFlightRevalidateSuccess,
  getFlightRevalidateFailure,
  getHotelByIdSuccess,
  getHotelByIdFailure,
  getHotelMetadataByIdSuccess,
  getHotelMetadataByIdFailure,
  getHotelReviewByIdSuccess,
  getHotelReviewByIdFailure,
  getHotelRoomsByIdSuccess,
  getHotelRoomsByIdFailure,
  getHotelsCordinateByCitySuccess,
  getHotelsCordinateByCityFailure,
  getAirportLoungeSuccess,
  getAirportLoungeFailure,
  getEventsSuccess,
  getEventsFailure,
  getEventByIdFailure,
  getEventByIdSuccess,
  getEventsCategoriesSuccess,
  getEventsCategoriesFailure,
  getEventsCountriesSuccess,
  getEventsCountriesFailure,
  getEventCitiesSuccess,
  getEventCitiesFailure,
  getHotelBookSuccess,
  getHotelBookFailure,
  getFlightBookSuccess,
  getFlightBookFailure,
  getSearchCarSuccess,
  getSearchCarFailer,
  getChangeFlightDataSucces,
  getChangeFlightDataFailure,
  cancelFlightSuccess,
  cancelFlightFailure,
  userResetPassWordOTPSuccess,
  userResetPassWordOTPFailure,
  getIpDataSuccess,
  getIpDataFailure,
  userAfterLoginResetPasswordSetSuccess,
  userAfterLoginResetPasswordSetFailure,
  userAfterLoginResetPasswordUpdateFailure,
  userAfterLoginResetPasswordUpdateSuccess,
  userEmailUpdateSuccess,
  userEmailUpdateOTPSuccess,
  userEmailUpdateOTPFailure,
  userEmailUpdateFailure,
  getFlightDetailsFailure,
  getFlightDetailsSuccess,
  getchangeFlightProcessDataSuccess,
  getchangeFlightProcessDataFailure,
  cancelHotelSuccess,
  cancelHotelFailure,
} from './userState';
import {
  userLogin,
  userVerifyOTP,
  userResendVerifyOTP,
  userSignUp,
  userPasswordReset,
  userPasswordUpdate,
  getTopDestnationList,
  getPopularAttractionCountriesList,
  getPopularAttractionList,
  getBackgroundImages,
  getAUserProfile,
  getCitiesList,
  getGeneratedTrip,
  getAirportsList,
  getPopularRoutes,
  getPopularCities,
  getHotelAndCities,
  getHotelList,
  getHotelByCityName,
  getHotelById,
  getHotelMetadataById,
  getHotelReviewById,
  getHotelRoomsById,
  getHotelsCordinateByCity,
  getAirportLounge,
  getEventsList,
  getEventById,
  getEventsCategories,
  getEventsCountries,
  getEventCities,
  getHotelBook,
  getFlightBook,
  getLocations,
  getSearchCarList,
  getChangeFlightSearchResults,
  getFlightSearchResults,
  cancelFlightResults,
  userResetPassWordOTP,
  userLoginResetPassWord,
  getIpData,
  userAfterLoginResetPasswordUpdate,
  userEmailUpdate,
  userEmailUpdateOTP,
  getFlightRevalidate,
  getFlightDetails,
  getchangeFlightProcessResult,
  cancelHotelResults,
} from '../services/service';
// user login
function* workGetUserLogin(action) {
  try {
    const user = yield userLogin(action.payload);

    const formattedUser = yield user.data;
    if (formattedUser.status === 'PENDING' || formattedUser.status === true) {
      localStorage.setItem('explrar_token', formattedUser.access_token);
      yield put(userLoginSuccess(formattedUser));
    } else {
      yield put(userLoginFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(userLoginFailure(formattedUser));
  }
}
// user OTP verify
function* workVerifyUserOTP(action) {
  try {
    const user = yield userVerifyOTP(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(userVerifyOTPSuccess(formattedUser));
    } else {
      yield put(userVerifyOTPFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(userVerifyOTPFailure(formattedUser));
  }
}
// user resend OTP
function* workResendVerifyOTP(action) {
  try {
    const user = yield userResendVerifyOTP(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status === 'PENDING') {
      yield put(userResendOTPSuccess(formattedUser));
    } else {
      yield put(userResendOTPFailure(formattedUser));
    }
  } catch (error) {
    console.log(error);
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(userResendOTPFailure(formattedUser));
  }
}
// user sign up
function* workSignUp(action) {
  try {
    const user = yield userSignUp(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status === 'PENDING') {
      yield put(userSignupSuccess(formattedUser));
    } else {
      yield put(userSignupFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(userSignupFailure(formattedUser));
  }
}
// user password reset
function* workResetPassword(action) {
  try {
    const user = yield userPasswordReset(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(userPasswordResetSuccess(formattedUser));
    } else {
      yield put(userPasswordResetFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(userPasswordResetFailure(formattedUser));
  }
}
// user password update
function* workUpdatePassword(action) {
  try {
    const user = yield userPasswordUpdate(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(userPasswordUpdateSuccess(formattedUser));
    } else {
      yield put(userPasswordUpdateFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(userPasswordUpdateFailure(formattedUser));
  }
}
function* workLoginUpdatePassword(action) {
  try {
    const user = yield userAfterLoginResetPasswordUpdate(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(userAfterLoginResetPasswordUpdateSuccess(formattedUser));
    } else {
      yield put(userAfterLoginResetPasswordUpdateFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(userAfterLoginResetPasswordUpdateFailure(formattedUser));
  }
}

function* workLoginResetPasswordOTP(action) {
  try {
    const user = yield userResetPassWordOTP(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(userResetPassWordOTPSuccess(formattedUser));
    } else {
      yield put(userResetPassWordOTPFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(userResetPassWordOTPFailure(formattedUser));
  }
}

function* workLoginPasswordSet(action) {
  try {
    const user = yield userLoginResetPassWord(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(userAfterLoginResetPasswordSetSuccess(formattedUser));
    } else {
      yield put(userAfterLoginResetPasswordSetFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(userAfterLoginResetPasswordSetFailure(formattedUser));
  }
}

function* workUpdateEmailOTPSent(action) {
  try {
    const user = yield userEmailUpdateOTP(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(userEmailUpdateOTPSuccess(formattedUser));
    } else {
      yield put(userEmailUpdateOTPFailure(formattedUser));
    }
  } catch (error) {
    console.log(error, 'error');
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(userEmailUpdateOTPFailure(formattedUser));
  }
}

function* workUpdateEmail(action) {
  try {
    const user = yield userEmailUpdate(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(userEmailUpdateSuccess(formattedUser));
    } else {
      yield put(userEmailUpdateFailure(formattedUser));
    }
  } catch (error) {
    console.log(error, 'error');
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(userEmailUpdateFailure(formattedUser));
  }
}

// get top destination list
function* workGetTopDestination(action) {
  try {
    const user = yield getTopDestnationList();
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getTopDestinationSuccess(formattedUser));
    } else {
      yield put(getTopDestinationFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getTopDestinationFailure(formattedUser));
  }
}
// get popular attraction countries list
function* workGetPopularAttractionCountriesList(action) {
  try {
    const user = yield getPopularAttractionCountriesList();
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getPopularAttractionCountriesSuccess(formattedUser));
    } else {
      yield put(getPopularAttractionCountriesFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getPopularAttractionCountriesFailure(formattedUser));
  }
}
// get popular attraction countries list
function* workGetPopularAttractionList(action) {
  try {
    const user = yield getPopularAttractionList(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getPopularAttractionSuccess(formattedUser));
    } else {
      yield put(getPopularAttractionFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getPopularAttractionFailure(formattedUser));
  }
}
// get backgroung images list
function* workGetBackgroundImagesList(action) {
  try {
    const user = yield getBackgroundImages(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getBackgroungImagesListSuccess(formattedUser));
    } else {
      yield put(getBackgroungImagesListFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getBackgroungImagesListFailure(formattedUser));
  }
}
// get user profile
function* workGetUserProfile(action) {
  try {
    const user = yield getAUserProfile(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getUserProfileSuccess(formattedUser));
    } else {
      yield put(getUserProfileFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getUserProfileFailure(formattedUser));
  }
}
// get cities list
function* workGetCitiesList(action) {
  try {
    const user = yield getCitiesList(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getCitiesListSuccess(formattedUser));
    } else {
      yield put(getCitiesListFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getCitiesListFailure(formattedUser));
  }
}
// get generated trip plan
function* workGetGeneratedTripPlan(action) {
  try {
    const user = yield getGeneratedTrip(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(generateTripSuccess(formattedUser));
    } else {
      yield put(generateTripFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(generateTripFailure(formattedUser));
  }
}
// get flights list
function* workGetFlightsList(action) {
  try {
    const user = yield getAirportsList(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getFlightSuccess(formattedUser));
    } else {
      yield put(getFlightFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getFlightFailure(formattedUser));
  }
}
// get poplur routes
function* workGetPopularRoutes(action) {
  try {
    const user = yield getPopularRoutes(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getFlightPopularRoutesSuccess(formattedUser));
    } else {
      yield put(getFlightPopularRoutesFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getFlightPopularRoutesFailure(formattedUser));
  }
}
// get flights search data
function* workGetFligthData(action) {
  try {
    const user = yield getFlightSearchResults(action.payload);
    const formattedUser = yield user.data;

    if (formattedUser.status) {
      yield put(getFlightDataSuccess(formattedUser));
    } else {
      yield put(getFlightDataFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getFlightDataFailure(formattedUser));
  }
}

function* workGetChangeFlightData(action) {
  try {
    const flightData = yield getChangeFlightSearchResults(action.payload);
    const formattedData = yield flightData.data;
    if (formattedData.status) {
      yield put(getChangeFlightDataSucces(formattedData));
    } else {
      yield put(getChangeFlightDataFailure(formattedData));
    }
  } catch (error) {
    const formattedData = {
      status: false,
      message: 'something went wrong',
    };
    yield put(getFlightDataFailure(formattedData));
  }
}

//-----change flight process

function* workGetChangeFlightProcessData(action) {
  try {
    console.log(
      action.payload,
      'action.payloadaction.payloadaction.payloadaction.payload'
    );
    const flightData = yield getchangeFlightProcessResult(action.payload);
    const formattedData = yield flightData.data;
    if (formattedData.status) {
      console.log(formattedData, 'success');
      yield put(getchangeFlightProcessDataSuccess(formattedData));
    } else {
      console.log(formattedData, 'fail');
      yield put(getchangeFlightProcessDataFailure(formattedData));
    }
  } catch (error) {
    const formattedData = {
      status: false,
      message: 'something went wrong',
    };
    yield put(getFlightDataFailure(formattedData));
  }
}
//cancel flight
function* workCancelFlight(action) {
  try {
    const flightData = yield cancelFlightResults(action.payload);
    const formattedData = yield flightData.data;
    if (formattedData.status) {
      yield put(cancelFlightSuccess(formattedData));
    } else {
      yield put(cancelFlightFailure(formattedData));
    }
  } catch (error) {
    const formattedData = {
      status: false,
      message: 'something went wrong',
    };
    yield put(getFlightDataFailure(formattedData));
  }
}
//hotels

// cancel hotel
function* workCancelHotel(action) {
  try {
    const hotelData = yield cancelHotelResults(action.payload);
    const formattedData = yield hotelData.data;
    if (formattedData.status) {
      yield put(cancelHotelSuccess(formattedData));
    } else {
      yield put(cancelHotelFailure(formattedData));
    }
  } catch (error) {
    const formattedData = {
      status: false,
      message: 'something went wrong',
    };
  }
}

// get top cities data
function* workGetTopCitiesData(action) {
  try {
    const user = yield getPopularCities(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getTopCitiesSuccess(formattedUser));
    } else {
      yield put(getTopCitiesFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getTopCitiesFailure(formattedUser));
  }
}

// get hotel and cities
function* workGetLocations(action) {
  try {
    const user = yield getLocations(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getLocationsSuccess(formattedUser));
    } else {
      yield put(getLocationsFailure(formattedUser));
    }
  } catch (error) {
    console.log(error);
  }
}
function* workGetHotelAndCities(action) {
  try {
    const user = yield getHotelAndCities(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getHotelAndCitiesSuccess(formattedUser));
    } else {
      yield put(getHotelAndCitiesFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getHotelAndCitiesFailure(formattedUser));
  }
}
// get hotel list data
function* workGetHotelListData(action) {
  try {
    const user = yield getHotelList(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getHotelListSuccess(formattedUser));
    } else {
      yield put(getHotelListFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getHotelListFailure(formattedUser));
  }
}

// get hotel by city data
function* workGetHotelByCityData(action) {
  try {
    const user = yield getHotelByCityName(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getHotelByCitySuccess(formattedUser));
    } else {
      yield put(getHotelByCityFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getHotelByCityFailure(formattedUser));
  }
}
// get flight revalid data
function* workGetFlightRevalidData(action) {
  try {
    const user = yield getFlightRevalidate(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getFlightRevalidateSuccess(formattedUser));
    } else {
      yield put(getFlightRevalidateFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getFlightRevalidateFailure(formattedUser));
  }
}

// get hotel by id
function* workGetHotelById(action) {
  try {
    const user = yield getHotelById(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getHotelByIdSuccess(formattedUser));
    } else {
      yield put(getHotelByIdFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getHotelByIdFailure(formattedUser));
  }
}
// get hotel metadata by id
function* workGetHotelMetadataById(action) {
  try {
    const user = yield getHotelMetadataById(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getHotelMetadataByIdSuccess(formattedUser));
    } else {
      yield put(getHotelMetadataByIdFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getHotelMetadataByIdFailure(formattedUser));
  }
}
// get hotel review by id
function* workGetHotelReviewById(action) {
  try {
    const user = yield getHotelReviewById(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getHotelReviewByIdSuccess(formattedUser));
    } else {
      yield put(getHotelReviewByIdFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getHotelReviewByIdFailure(formattedUser));
  }
}
// get hotel rooms by id
function* workGetHotelRoomsById(action) {
  try {
    const user = yield getHotelRoomsById(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getHotelRoomsByIdSuccess(formattedUser));
    } else {
      yield put(getHotelRoomsByIdFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getHotelRoomsByIdFailure(formattedUser));
  }
}
// get hotels cordinate by city
function* workGetHotelsCordinateByCity(action) {
  try {
    const user = yield getHotelsCordinateByCity(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getHotelsCordinateByCitySuccess(formattedUser));
    } else {
      yield put(getHotelsCordinateByCityFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getHotelsCordinateByCityFailure(formattedUser));
  }
}
// booking apis
function* workGetAirportLounge(action) {
  try {
    const user = yield getAirportLounge(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getAirportLoungeSuccess(formattedUser));
    } else {
      yield put(getAirportLoungeFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getAirportLoungeFailure(formattedUser));
  }
}

// Events API
function* workGetEvents(action) {
  try {
    const user = yield getEventsList(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getEventsSuccess(formattedUser));
    } else {
      yield put(getEventsFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getEventsFailure(formattedUser));
  }
}

function* workGetEventById(action) {
  try {
    const user = yield getEventById(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getEventByIdSuccess(formattedUser));
    } else {
      yield put(getEventByIdFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getEventByIdFailure(formattedUser));
  }
}

function* workGetEventsCategories(action) {
  try {
    const user = yield getEventsCategories(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getEventsCategoriesSuccess(formattedUser));
    } else {
      yield put(getEventsCategoriesFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getEventsCategoriesFailure(formattedUser));
  }
}
function* workGetEventsCountries(action) {
  try {
    const user = yield getEventsCountries(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getEventsCountriesSuccess(formattedUser));
    } else {
      yield put(getEventsCountriesFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getEventsCountriesFailure(formattedUser));
  }
}
function* workGetEventCities(action) {
  try {
    const user = yield getEventCities(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getEventCitiesSuccess(formattedUser));
    } else {
      yield put(getEventCitiesFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getEventsCountriesFailure(formattedUser));
  }
}
// get hotel books by id
function* workGetHotelBookById(action) {
  try {
    const user = yield getHotelBook(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getHotelBookSuccess(formattedUser));
    } else {
      yield put(getHotelBookFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getHotelBookFailure(formattedUser));
  }
}

// flight book by id
function* workGetFlightBookById(action) {
  try {
    const user = yield getFlightBook(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getFlightBookSuccess(formattedUser));
    } else {
      yield put(getFlightBookFailure(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getFlightBookFailure(formattedUser));
  }
}

// get search car rental data
function* workGetSearchCar(action) {
  try {
    const user = yield getSearchCarList(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getSearchCarSuccess(formattedUser));
    } else {
      yield put(getSearchCarFailer(formattedUser));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getSearchCarFailer(formattedUser));
  }
}

function* workGetFlightDetails(action) {
  try {
    const user = yield getFlightDetails(action.payload);
    const formattedUser = yield user.data;
    if (formattedUser.status) {
      yield put(getFlightDetailsSuccess(formattedUser.data));
    } else {
      yield put(getFlightDetailsFailure(formattedUser.message));
    }
  } catch (error) {
    const formattedUser = {
      status: false,
      message: 'Something went wrong',
    };
    yield put(getFlightDetailsFailure(formattedUser.message));
  }
}

// Get Ip data
function* workGetIpData(action) {
  try {
    const ipData = yield getIpData(action.payload);
    const formattedIpData = yield ipData.data;
    if (ipData.status) {
      yield put(getIpDataSuccess(formattedIpData));
    } else {
      yield put(getIpDataFailure(formattedIpData));
    }
  } catch (error) {}
}

function* userSaga() {
  yield takeEvery('user/userLogin', workGetUserLogin);
  yield takeEvery('user/userVerifyOTP', workVerifyUserOTP);
  yield takeEvery('user/userResendOTP', workResendVerifyOTP);
  yield takeEvery('user/userSignup', workSignUp);
  yield takeEvery('user/userPasswordReset', workResetPassword);
  yield takeEvery('user/userPasswordUpdate', workUpdatePassword);
  yield takeEvery(
    'user/userAfterLoginResetPasswordUpdate',
    workLoginUpdatePassword
  );
  yield takeEvery('user/userResetPassWordOTP', workLoginResetPasswordOTP);
  yield takeEvery('user/userAfterLoginResetPasswordSet', workLoginPasswordSet);
  yield takeEvery('user/userEmailUpdateOTP', workUpdateEmailOTPSent);
  yield takeEvery('user/userEmailUpdate', workUpdateEmail);
  yield takeEvery('user/getTopDestination', workGetTopDestination);
  yield takeEvery(
    'user/getPopularAttractionCountries',
    workGetPopularAttractionCountriesList
  );
  yield takeEvery('user/getPopularAttraction', workGetPopularAttractionList);
  yield takeEvery('user/getBackgroungImagesList', workGetBackgroundImagesList);
  yield takeEvery('user/getUserProfile', workGetUserProfile);
  yield takeEvery('user/getCitiesList', workGetCitiesList);
  yield takeEvery('user/generateTrip', workGetGeneratedTripPlan);
  yield takeEvery('user/getFlightsList', workGetFlightsList);
  yield takeEvery('user/getFlightsPopularRoutesList', workGetPopularRoutes);
  yield takeEvery('user/getFlightsData', workGetFligthData);
  yield takeEvery('user/getTopCities', workGetTopCitiesData);
  yield takeEvery('user/getHotelAndCities', workGetHotelAndCities);
  yield takeEvery('user/getHotelList', workGetHotelListData);
  yield takeEvery('user/getHotelByCity', workGetHotelByCityData);
  yield takeEvery('user/getFlightRevalidate', workGetFlightRevalidData);
  yield takeEvery('user/getHotelById', workGetHotelById);
  yield takeEvery('user/getHotelMetadataById', workGetHotelMetadataById);
  yield takeEvery('user/getHotelReviewById', workGetHotelReviewById);
  yield takeEvery('user/getHotelRoomsById', workGetHotelRoomsById);
  yield takeEvery(
    'user/getHotelsCordinateByCity',
    workGetHotelsCordinateByCity
  );
  yield takeEvery('user/getAirportLounge', workGetAirportLounge);
  yield takeEvery('user/getEventsList', workGetEvents);
  yield takeEvery('user/getEventById', workGetEventById);
  yield takeEvery('user/getEventsCategories', workGetEventsCategories);
  yield takeEvery('user/getHotelBook', workGetHotelBookById);
  yield takeEvery('user/getEventsCountries', workGetEventsCountries);
  yield takeEvery('user/getEventCities', workGetEventCities);
  yield takeEvery('user/getFlightBook', workGetFlightBookById);
  yield takeEvery('user/getLocations', workGetLocations);
  yield takeEvery('user/getSearchCarList', workGetSearchCar);
  yield takeLatest('user/getFlightDetails', workGetFlightDetails);
  yield takeLatest('user/getChangeFlightData', workGetChangeFlightData);
  yield takeLatest(
    'user/getchangeFlightProcessData',
    workGetChangeFlightProcessData
  );
  yield takeLatest('user/cancelFlight', workCancelFlight);
  yield takeLatest('user/cancelHotel', workCancelHotel);
  yield takeEvery('user/getIpData', workGetIpData);
}

export default userSaga;
