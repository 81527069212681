import CryptoJS from 'crypto-js';

export const formatCurrency = (value) =>
  new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(
    value
  );

export const formatBucks = (value) => {
  if (!value) return 0;
  return new Intl.NumberFormat().format(value); // Returns formatted string with commas
};

export const currencyFormat = (value) => {
  if (!value) return '0.00';
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const formatLatterCurrency = (value) => {
  const formattedValue = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'code',
  }).format(value);

  return 'USD ' + formattedValue.replace('USD', '').trim();
};

export const dateFormatter = (value) => {
  return new Date(value)
    .toLocaleString('en-US', {
      timeZone: 'UTC',
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(
      /(\w{3}), (\w{3}) (\d{1,2}), (\d{4}), (\d{1,2}):(\d{2}) (AM|PM)/,
      (_, w1, w2, d, y, h, m, ap) =>
        `${w1}, ${w2.toLowerCase()} ${d} ${y}, ${h}:${m} ${ap}`
    );
};
export const formatDuration = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours} hours ${mins} mins`;
};

export const MinutesBetweenTwoDates = (date1, date2) => {
  const Date1 = new Date(date1);
  const Date2 = new Date(date2);
  const diffInMilliseconds = Math.abs(Date2 - Date1);
  const diffInMinutes = Math.floor(diffInMilliseconds / 60000);

  return diffInMinutes;
};

export const countPassengers = (passengers) => {
  return passengers?.reduce(
    (counts, passenger) => {
      if (passenger.type === 'adult') {
        counts.adult += 1;
      } else if (passenger.type === 'child') {
        counts.child += 1;
      }
      return counts;
    },
    { adult: 0, child: 0 }
  );
};

export const classFinder = (classcode) => {
  if (classcode === 'Y') return 'Economy';
  if (classcode === 'F') return 'First';
  if (classcode === 'S') return 'PremiumEconomy';
  if (classcode === 'C') return 'Business';
};

export const countCurrency = (data) => {
  return (
    data?.flight?.AirRevalidateResponse?.AirRevalidateResult?.FareItineraries
      ?.FareItinerary?.AirItineraryFareInfo?.FareBreakdown?.[0]?.PenaltyDetails
      ?.Currency || 'USD'
  );
};

export const countChangeFees = (data) => {
  return new Intl.NumberFormat('en-US').format(
    parseFloat(
      data?.flight?.AirRevalidateResponse?.AirRevalidateResult?.FareItineraries
        ?.FareItinerary?.AirItineraryFareInfo?.FareBreakdown?.[0]
        ?.PenaltyDetails?.ChangePenaltyAmount || 0
    )
  );
};

export const countCancelFees = (data) => {
  return new Intl.NumberFormat('en-US').format(
    parseFloat(
      data?.flight?.AirRevalidateResponse?.AirRevalidateResult?.FareItineraries
        ?.FareItinerary?.AirItineraryFareInfo?.FareBreakdown?.[0]
        ?.PenaltyDetails?.RefundPenaltyAmount || 0
    )
  );
};
export const decryptData = (ciphertext) => {
  try {
    const bytes = CryptoJS.AES.decrypt(
      ciphertext,
      'thisis#$@$@^@TY@I$^@$@$@#$$@#@$$dataHideSECRT#LY#&XBZF$'
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (error) {
    return null;
  }
};

export const getPaxDetails = (dataObj, ticketInfo) => {
  return dataObj?.passengerInfo?.passengersInfo.map((data, i) => {
    return {
      type: data.type === 'adult' ? 'ADT' : 'CHD',
      title: data.type === 'adult' ? 'Mr' : 'Miss',
      firstName: data.firstName,
      lastName: data.lastName,
      eTicket: ticketInfo?.eTicket,
    };
  });
};

export const paypalSupportedCurrency = (currency) => {
  const currencies = [
    'AUD',
    'BRL',
    'CAD',
    'CNY',
    'CZK',
    'DKK',
    'EUR',
    'HKD',
    'HUF',
    'ILS',
    'JPY',
    'MYR',
    'MXN',
    'TWD',
    'NZD',
    'NOK',
    'PHP',
    'PLN',
    'GBP',
    'SGD',
    'SEK',
    'CHF',
    'THB',
    'USD',
  ];
  if (currencies.includes(currency)) {
    return currency;
  }

  return 'USD';
};
