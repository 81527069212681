import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router

function Footer() {
  const [openSections, setOpenSections] = useState({});

  const toggleSection = (sectionName) => {
    setOpenSections({
      ...openSections,
      [sectionName]: !openSections[sectionName],
    });
  };

  return (
    <footer
      className="bg-light-black"
      style={{
        paddingTop: '1rem',
        paddingBottom: '1rem',
        zIndex: 100,
        position: 'relative',
      }}
    >
      <div className="container pb-1">
        <div className="footer-box d-flex justify-content-between flex-wrap">
          <div className="footer-column-1">
            <ul className="pe-sm-0 pt-0">
              <li className="fs-18 fw-500 ff-inter color-white d-sm-block d-none">
                Help
              </li>
              <button
                className="btn btn-link fs-18 fw-500 ff-inter color-white d-sm-none d-flex justify-content-between align-items-center gap-3"
                onClick={() => toggleSection('help')}
              >
                {openSections['help'] ? '' : ''} Help
                <i
                  className={` fa  ${openSections['help'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                ></i>
              </button>
              <div className={`${openSections['help'] ? 'open' : 'collapsed'}`}>
                <li>
                  <Link
                    to="/customer-support"
                    className="fs-16 fw-400 ff-inter color-white opacity-70 mt-3"
                  >
                    Customer Support
                  </Link>
                </li>
                <li className="pt-1">
                  <Link
                    to="/privacy-policy"
                    className="fs-16 fw-400 ff-inter color-white opacity-70 mt-2"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li className="pt-1">
                  <Link
                    to="/terms-service"
                    className="fs-16 fw-400 ff-inter color-white opacity-70 mt-2"
                  >
                    Terms of Services
                  </Link>
                </li>
              </div>
            </ul>
          </div>
          <div className="footer-column-2">
            <ul className="pe-sm-0 pt-0">
              <li className="fs-18 fw-500 ff-inter color-white d-sm-block d-none">
                About
              </li>
              <button
                className="btn btn-link fs-18 fw-500 ff-inter color-white d-sm-none d-flex justify-content-between align-items-center gap-3"
                onClick={() => toggleSection('about')}
              >
                {openSections['about'] ? '' : ''} About
                <i
                  className={` fa  ${openSections['about'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                ></i>
              </button>
              <div
                className={`${openSections['about'] ? 'open' : 'collapsed'}`}
              >
                <li>
                  <Link
                    to="/our-story"
                    className="fs-16 fw-400 ff-inter color-white opacity-70 mt-3"
                  >
                    Our Story
                  </Link>
                </li>

                <li className="pt-1">
                  <Link
                    to="/blog-page"
                    className="fs-16 fw-400 ff-inter color-white opacity-70 mt-2"
                  >
                    Blog
                  </Link>
                </li>
              </div>
            </ul>
          </div>
          <div className="footer-column-3">
            <ul>
              <li className="fs-18 fw-500 ff-inter color-white d-sm-block d-none">
                Explore
              </li>
              <button
                className="btn btn-link fs-18 fw-500 ff-inter color-white d-sm-none d-flex justify-content-between align-items-center gap-3"
                onClick={() => toggleSection('explore')}
              >
                {openSections['explore'] ? '' : ''} Explore
                <i
                  className={` fa  ${openSections['explore'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                ></i>
              </button>
              <div
                className={`${openSections['explore'] ? 'open' : 'collapsed'}`}
              >
                <li>
                  <Link
                    to="/"
                    className="fs-16 fw-400 ff-inter color-white opacity-70 mt-3"
                  >
                    Loyalty Program
                  </Link>
                </li>
                <li className="pt-1">
                  <Link
                    to="/download-app"
                    className="fs-16 fw-400 ff-inter color-white opacity-70 mt-2"
                  >
                    Download Our App
                  </Link>
                </li>
                {/* <li className="pt-1">
                  <Link
                    to="/privacy-policy"
                    className="fs-16 fw-400 ff-inter color-white opacity-70 mt-2"
                  >
                    Privacy Policy
                  </Link>
                </li> */}
                {/* <li className="pt-1">
                  <Link
                    to="/package-list"
                    className="fs-16 fw-400 ff-inter color-white opacity-70 mt-2"
                  >
                    Packing List Generator
                  </Link>
                </li> */}
              </div>
            </ul>
          </div>
          <div className="footer-column-4">
            <p className="fs-18 fw-500 ff-inter color-white text-sm-start text-center">
              Payment methods
            </p>
            <div className="mt-1 pt-xl-1 text-sm-start text-center">
              <img
                src="../../../Assets/icons/payment-icons.svg"
                alt="paypal-icon"
                width="250px"
                height="100%"
              />
            </div>
            <div className="mt-4 pt-xl-2 br-top">
              <p className="fs-18 fw-500 ff-inter color-white text-sm-start text-center">
                Stay Connected
              </p>
              <div className="text-sm-start text-center">
                <Link
                  to="https://www.facebook.com/explrar"
                  target="_blank"
                  className="mx-1"
                >
                  <img src="../../../Assets/icons/fb-icon.svg" alt="fb-icon" />
                </Link>

                <Link
                  to="https://instagram.com/explrar"
                  target="_blank"
                  className="mx-xx-1 me-1"
                >
                  <img
                    src="../../../Assets/icons/insta-icon.svg"
                    alt="insta-icon"
                  />
                </Link>

                <Link
                  to="https://x.com/explrar"
                  target="_blank"
                  className="me-1 mt-3"
                >
                  <img
                    src="../../../Assets/icons/new-twitter-icon.svg"
                    alt="X-icon"
                  />
                </Link>

                <Link
                  to="https://www.threads.net/@explrar"
                  target="_blank"
                  className="me-1 mt-3"
                >
                  <img src="../../../Assets/icons/@-icon.svg" alt="@-icon" />
                </Link>

                <Link
                  to="https://www.tiktok.com/@explrar"
                  target="_blank"
                  className="me-1 mt-3"
                >
                  <img
                    src="../../../Assets/icons/Tiktok.svg"
                    alt="tiktok icon"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="footer-bottom-border mt-4" />
      <div className="text-center mt-sm-4 pt-3 pb-1">
        <Link to="/">
          <img
            className="nav-logo"
            src="../../../Assets/Img/svg/footer-logo.svg"
            alt="footer-logo"
          />
        </Link>
        <p className="fs-16 fw-400 ff-inter color-white mt-3 mt-sm-4 opacity-30">
          Copyright Ⓒ 2024, Explrar Pte. Ltd. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
