import React from 'react';
import './BlogPage.css';
import LatestNewsAbout from './LatestNewsAbout';

const allImages = {
  arrowLeftImage: '../../../Assets/icons/Arrow left.svg',
  arrowRightImage: '../../../Assets/icons/Arrow right.svg',
  emailIcons: '../../../Assets/icons/Mail.svg',
};

export default function BlogPage() {
  const blogPageHero = {
    image: '../../../Assets/Img/png/blog-page-hero-bg.png',
    nameAndDate: 'Olivia Rhye • 20 Jan 2024',
    title: 'UX review presentations',
    subTitle:
      'How do you create compelling presentations that wow your colleagues and impress your managers?',
    category: [{ cat1: 'Product', cat2: 'Tools', cat3: 'SaaS' }],
  };

  const blogPagesCard = [
    {
      image: '../../../Assets/Img/png/blog-page-image.png',
      nameAndDate: 'Olivia Rhye • 20 Jan 2024',
      title: 'UX review presentations',
      subTitle:
        'How do you create compelling presentations that wow your colleagues and impress your managers?',
      category: [{ cat1: 'Product', cat2: 'Tools', cat3: 'SaaS' }],
    },
    {
      image: '../../../Assets/Img/png/blog-page-image1.png',
      nameAndDate: 'Olivia Rhye • 20 Jan 2024',
      title: 'UX review presentations',
      subTitle:
        'How do you create compelling presentations that wow your colleagues and impress your managers?',
      category: [{ cat1: 'Product', cat2: 'Tools', cat3: 'SaaS' }],
    },
    {
      image: '../../../Assets/Img/png/blog-page-image2.png',
      nameAndDate: 'Olivia Rhye • 20 Jan 2024',
      title: 'UX review presentations',
      subTitle:
        'How do you create compelling presentations that wow your colleagues and impress your managers?',
      category: [{ cat1: 'Product', cat2: 'Tools', cat3: 'SaaS' }],
    },
    {
      image: '../../../Assets/Img/png/blog-page-image3.png',
      nameAndDate: 'Olivia Rhye • 20 Jan 2024',
      title: 'UX review presentations',
      subTitle:
        'How do you create compelling presentations that wow your colleagues and impress your managers?',
      category: [{ cat1: 'Product', cat2: 'Tools', cat3: 'SaaS' }],
    },
    {
      image: '../../../Assets/Img/png/blog-page-image4.png',
      nameAndDate: 'Olivia Rhye • 20 Jan 2024',
      title: 'UX review presentations',
      subTitle:
        'How do you create compelling presentations that wow your colleagues and impress your managers?',
      category: [{ cat1: 'Product', cat2: 'Tools', cat3: 'SaaS' }],
    },
    {
      image: '../../../Assets/Img/png/blog-page-image5.png',
      nameAndDate: 'Olivia Rhye • 20 Jan 2024',
      title: 'UX review presentations',
      subTitle:
        'How do you create compelling presentations that wow your colleagues and impress your managers?',
      category: [{ cat1: 'Product', cat2: 'Tools', cat3: 'SaaS' }],
    },
  ];

  return (
    <>
      <section>
        {/* Blog Page Header Card Start  */}
        <div className="blog-page-header-container">
          <div className="blog-page-header-title">
            <p className="blog-page-orange-color">Our blog</p>
            <h1 className="blog-page-title">Resources and Insights</h1>
            <p className="blog-page-sub-title">
              The latest our company news, interviews, technologies, and
              resources.
            </p>
          </div>

          <div>
            <div>
              <img
                style={{ width: '100%', height: '100%' }}
                src={blogPageHero?.image}
                alt=""
              />
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}
              >
                <p
                  style={{ marginTop: '24px' }}
                  className="blog-page-card-nameAndDate"
                >
                  {blogPageHero.nameAndDate}
                </p>
                <h2
                  style={{ fontSize: '30px' }}
                  className="blog-page-card-title"
                >
                  {blogPageHero.title}
                </h2>
                <p className="blog-page-card-subtitle">
                  {blogPageHero.subTitle}
                </p>
              </div>

              {blogPageHero.category.map((cat, ind) => (
                <div
                  style={{ display: 'flex', gap: '12px', marginTop: '24px' }}
                >
                  <p className="blog-page-card-category-btn1">{cat.cat1}</p>
                  <p className="blog-page-card-category-btn2">{cat.cat2}</p>
                  <p className="blog-page-card-category-btn3">{cat.cat3}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Blog Page Header Card End  */}

        {/* Blog Page Card Start  */}
        <div className="blog-page-card-container">
          {blogPagesCard.map((blog, ind) => (
            <div key={ind}>
              <img
                style={{ width: '100%', height: 'auto' }}
                src={blog?.image}
                alt=""
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '6px',
                  marginTop: '20px',
                }}
              >
                <p className="blog-page-card-nameAndDate">
                  {blog?.nameAndDate}
                </p>
                <h2 className="blog-page-card-title">{blog?.title}</h2>
                <p className="blog-page-card-subtitle">{blog?.subTitle}</p>
              </div>
              <div>
                {blog.category.map((cat, ind) => (
                  <div
                    style={{ display: 'flex', gap: '12px', marginTop: '24px' }}
                    key={ind}
                  >
                    <p className="blog-page-card-category-btn1">{cat?.cat1}</p>
                    <p className="blog-page-card-category-btn2">{cat?.cat2}</p>
                    <p className="blog-page-card-category-btn3">{cat?.cat3}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            paddingTop: '64px',
            width: '64%',
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <button
            style={{
              display: 'flex',
              gap: '6px',
              alignItems: 'center',
              background: 'none',
              border: 'none',
            }}
          >
            <img
              style={{ width: '18px', height: '18px' }}
              src={allImages.arrowLeftImage}
              alt=""
            />
            <p
              style={{ fontSize: '14px', fontWeight: '600', color: '#5F5F5F' }}
            >
              Previous
            </p>
          </button>

          <p style={{ fontSize: '14px', fontWeight: '600', color: '#5F5F5F' }}>
            Page 1 of 15
          </p>

          <button
            style={{
              display: 'flex',
              gap: '6px',
              alignItems: 'center',
              background: 'none',
              border: 'none',
            }}
          >
            <p
              style={{ fontSize: '14px', fontWeight: '600', color: '#5F5F5F' }}
            >
              Next
            </p>
            <img
              style={{ width: '18px', height: '18px' }}
              src={allImages.arrowRightImage}
              alt=""
            />
          </button>
        </div>
        {/* Blog Page Card End  */}

        {/* Latest news about Explrar start  */}
        <div>
          <LatestNewsAbout />
        </div>
        {/* Latest news about Explrar End */}

        {/* Join our newsletter Section start */}
        <section>
          <div className="blog-page-newsletter-container">
            <div>
              <h2
                style={{
                  fontSize: '20px',
                  color: '#101012',
                  fontWeight: '600',
                }}
              >
                Join our newsletter
              </h2>
              <p style={{ color: '#101012' }}>
                We’ll send you a nice letter once per week. No spam.
              </p>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <div>
                <label htmlFor="email"></label>
                <div className="input-decoration d-flex ">
                  <img src={allImages.emailIcons} alt="Blog page email icons" />
                  <input
                    className="what-to-pack-from-input-field"
                    type="text"
                    name="location"
                    id="location"
                    placeholder="Enter your email"
                  />
                </div>
              </div>

              <div>
                <button
                  style={{ marginTop: '20px' }}
                  className="blog-page-newsletter-subscribe-btn"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* Join our newsletter Section End */}
      </section>
    </>
  );
}
