import * as React from 'react';
import './customerSupportFaq.css';
import FaqAccordion from './FaqAccordion';
import OpenFaqAccordion from './openFaqAccordion';

export default function CustomerSupportFaq({
  content,
  isClicked,
  setIsClicked,
}) {
  const [expandedIndex, setExpandedIndex] = React.useState(null);
  const [clicked, setClicked] = React.useState(content[0]);

  const handleAccordionChange = (index) => (event, isExpanded) => {
    setExpandedIndex(isExpanded ? index : null);
  };
  return (
    <div
      className={`${isClicked && clicked ? 'customer-Support-Faq-main-container' : 'customer-support-faq-main-container-option'}`}
    >
      {isClicked && clicked && (
        <section style={{ height: '480px' }}>
          <OpenFaqAccordion
            isClicked={isClicked}
            setClicked={setIsClicked}
            item={clicked}
            expandedIndex={expandedIndex}
            handleAccordionChange={handleAccordionChange}
          />
        </section>
      )}

      <section style={{ height: '480px' }}>
        <FaqAccordion
          clickedItem={clicked}
          setClickedItem={setClicked}
          items={content}
          expandedIndex={expandedIndex}
          handleAccordionChange={handleAccordionChange}
          isClicked={isClicked}
          setIsClicked={setIsClicked}
        />
      </section>
    </div>
  );
}
