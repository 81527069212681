import { createContext, useContext, useState } from 'react';

const flightDetailsContext = createContext();

const FlightDetailsProvider = ({ children }) => {
  const [duration, setDuration] = useState(0);
  const [departFlight, setDepartFlight] = useState({
    data: [],
    session: { id: '', fare_code: '', depart: '', return: '' },
  });
  const [sessionIds, setSessionIds] = useState([]);
  const [fareCodes, setFareCodes] = useState([]);
  const [headings, setHeadings] = useState([]);
  const [headings2, setHeadings2] = useState([]);
  const [stops, setStops] = useState([]);
  const [departureTime, setDepartureTime] = useState([]);
  const [arrivalTime, setArrivalTime] = useState([]);
  const [departMultiFlight, setDepartMultiFlight] = useState([]);
  const [airlineInclude, setAirlineInclude] = useState([]);

  const handleDepartFlight = (data) => {
    setDepartFlight(data);
  };

  const handleSesstionIds = (data) => {
    setSessionIds(data);
  };

  const handleFareCodes = (data) => {
    setFareCodes(data);
  };

  const handleHeadings = (data) => {
    setHeadings(data);
  };
  const handleHeadings2 = (data) => {
    setHeadings2(data);
  };
  const handleStop = (data) => {
    setStops(data);
  };
  const handleDeparture = (data) => {
    setDepartureTime(data);
  };
  const handleArrival = (data) => {
    setArrivalTime(data);
  };
  const handleDepartMultiFlights = (data) => {
    setDepartMultiFlight(data);
  };

  const handleAirlineIncludes = (data) => {
    console.log('DAAATA', data);
    setAirlineInclude(data);
  };

  const handleSetDuration = (data) => {
    setDuration(data);
  };

  const resetFilters = () => {
    setStops([]);
    setAirlineInclude([]);
    setDuration(0);
    setArrivalTime([]);
    setDepartureTime([]);
  };

  return (
    <flightDetailsContext.Provider
      value={{
        handleDepartFlight,
        departFlight,
        handleSesstionIds,
        sessionIds,
        handleFareCodes,
        fareCodes,
        handleHeadings,
        headings,
        handleHeadings2,
        headings2,
        resetFilters,
        stops,
        departureTime,
        arrivalTime,
        handleDeparture,
        handleArrival,
        handleStop,
        handleDepartMultiFlights,
        departMultiFlight,
        handleAirlineIncludes,
        airlineInclude,
        handleSetDuration,
        duration,
      }}
    >
      {children}
    </flightDetailsContext.Provider>
  );
};

const useFlightDetail = () => {
  const context = useContext(flightDetailsContext);

  if (context === undefined)
    throw new Error(
      'flightBookingContext was used outside the FlightBookingProvider.'
    );

  return context;
};

export { useFlightDetail, FlightDetailsProvider };
