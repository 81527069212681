import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import './privacyPolicy.css';

export default function PrivacyPolicy() {
  return (
    <>
      <main className="privacy-policy-main">
        <div className="privacy-policy-container">
          <p className="privacy-policy-update-date">
            Current as of 23 May, 2024
          </p>
          <h1 className="privacy-policy-container-h1">Privacy Policy</h1>
          <p className="privacy-policy-container-p2">
            Your privacy is important to us at Explrar. We respect your privacy
            regarding any information we may collect from you across our
            website.
          </p>
        </div>
        <section className="privacy-policy-content-container">
          <div className="explrar-privacy-policy-text">
            <p className="similar-font-size">
              This Privacy Policy is provided by Explrar Pte. Ltd., located at
              68 Circular Road #02-01, Singapore 049422. At Explrar, we are
              committed to safeguarding the privacy and security of our users.
              This policy outlines how we handle personal data collected through
              our website, mobile applications, and social media platforms.
            </p>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">Purpose of this Policy</h2>
            <p className="similar-font-size">
              The purpose of this policy is to inform you about how we collect,
              use, secure, and share your personal data when you visit our site,
              use our services, or interact with us electronically. By engaging
              with our platforms, you consent to the practices described in this
              document.
            </p>
          </div>
          <div className="similar-margin-div ">
            <h2 className="similar-design-h2">Data Collection</h2>
            <p className="similar-design-p">
              Information Provided by You: We collect information that you
              voluntarily provide when using our services, including but not
              limited to:
            </p>
            <div className="privacy-policy-list-item-container">
              <div className="privacy-policy-list-item">
                <FiberManualRecordIcon
                  sx={{ fontSize: '6px', marginLeft: '12px' }}
                />
                <p className="similar-design-p">
                  Personal identifiers like your name, email address, and
                  contact details.
                </p>
              </div>
              <div className="privacy-policy-list-item">
                <FiberManualRecordIcon
                  sx={{ fontSize: '6px', marginLeft: '12px' }}
                />
                <p className="similar-design-p">
                  Transactional details from your bookings.
                </p>
              </div>
              <div className="privacy-policy-list-item">
                <FiberManualRecordIcon
                  sx={{ fontSize: '6px', marginLeft: '12px' }}
                />
                <p className="similar-design-p">
                  Content you generate or that relates to your account.
                </p>
              </div>
            </div>
            <p className="similar-design-p">
              Information Collected Automatically: When you visit our website or
              use our services, certain information is automatically recorded.
              This may include:
            </p>
            <div className="privacy-policy-list-item-container-2">
              <div className="privacy-policy-list-item">
                <FiberManualRecordIcon
                  sx={{ fontSize: '6px', marginLeft: '12px' }}
                />
                <p className="similar-design-p">
                  Your IP address and geographic location.
                </p>
              </div>
              <div className="privacy-policy-list-item">
                <FiberManualRecordIcon
                  sx={{ fontSize: '6px', marginLeft: '12px' }}
                />
                <p className="similar-design-p">
                  Device and connection information.
                </p>
              </div>
              <div className="privacy-policy-list-item">
                <FiberManualRecordIcon
                  sx={{ fontSize: '6px', marginLeft: '12px' }}
                />
                <p className="similar-design-p">
                  Usage data and user preference.
                </p>
              </div>
            </div>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">Use of Your Information</h2>
            <p className="similar-design-p">
              Service Provision: To administer our services, communicate
              important account and service information, and provide customer
              support.
            </p>
            <p className="similar-design-p margin-top-20px">
              Personalization: To enhance your experience, tailor content, and
              make recommendations based on your preferences and interactions.
            </p>
            <p className="similar-design-p margin-top-20px">
              Communication: To send updates, security alerts, and
              administrative messages.
            </p>
            <p className="similar-design-p margin-top-20px">
              Marketing and Promotions: Subject to your consent, to send you
              promotional messages and marketing offers.
            </p>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">Data Security</h2>
            <p className="similar-design-p">
              We implement robust security measures to protect your data from
              unauthorized access, alteration, disclosure, or destruction. These
              include encryption, firewalls, and secure server facilities.
            </p>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">Data Sharing and Disclosure</h2>
            <p className="similar-design-p">
              Third-Party Services: We share data with trusted partners who
              perform services on our behalf, under confidentiality agreements.
              These companies do not have any independent right to share this
              information.
            </p>
            <p className="similar-design-p margin-top-20px">
              Compliance with Laws: We may disclose personal data in response to
              legal requests by public authorities or when required by law.
            </p>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">Your Privacy Rights</h2>
            <p className="similar-design-p">
              Access and Control: You have the right to access, update, and
              correct inaccuracies in your personal data in our custody and
              control, subject to certain exceptions prescribed by law.
            </p>
            <p className="similar-design-p margin-top-20px">
              Deletion and Restriction: You can request the deletion or
              restriction of your personal data.
            </p>
            <p className="similar-design-p margin-top-20px">
              Data Portability: You have the right to receive a copy of the data
              you have provided in a structured, commonly used, and
              machine-readable format.
            </p>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">Changes to our Privacy Policy</h2>
            <p className="similar-design-p">
              We may update this policy to reflect changes to our information
              practices. If we make any material changes, we will notify you by
              email or by means of a notice on our website prior to the change
              becoming effective.
            </p>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">Contact Us</h2>
            <p className="similar-design-p">
              If you have any questions about this Privacy Policy, please
              contact us at
              <a href="mailto:support@explrar.com">support@explrar.com</a>
            </p>
          </div>
          <div className="similar-margin-div">
            <h2 className="similar-design-h2">Facebook App Integration</h2>
            <p className="similar-design-p">
              We enable login with Facebook to facilitate a seamless sign-in
              process. By using this feature, you authorize us to access certain
              Facebook account information, including but not limited to your
              public profile. The use and transfer of information received from
              Facebook are governed by the Facebook Platform Policies.
            </p>
          </div>
        </section>
      </main>
    </>
  );
}
