import { useState } from 'react';
import './BlogPageDetails.css';
import SharedBlogCard from '../sharedBlogCard';

export default function BlogPageDetails() {
  const [viewAll, setViewAll] = useState(3);
  const [viewBlog, setViewBlog] = useState(3);
  const blogs = [
    {
      id: 1,
      img: '../../../Assets/Img/png/blog-Page-Image6.png',
      author: 'Olivia Rhye',
      publishedDate: new Date(),
      title: 'UX review presentations',
      content:
        'How do you create compelling presentations that wow your colleagues and impress your managers?',
      tags: ['Product', 'Research', 'Presentation'],
    },
    {
      id: 2,
      img: '../../../Assets/Img/png/blog-Page-Image.png',
      author: 'Phoenix Baker',
      publishedDate: new Date(),
      title: 'Migrating to Linear 101',
      content:
        'Linear helps streamline software projects, sprints, tasks, and bug tracking. Here’s how to get started.',
      tags: ['Product', 'Tools', 'SaaS'],
    },
    {
      id: 3,
      img: '../../../Assets/Img/png/blog-Page-Image1.png',
      author: 'Lana Steiner',
      publishedDate: new Date(),
      title: 'Building your API stack',
      content:
        'The rise of RESTful APIs has been met by a rise in tools for creating, testing, and managing them.',
      tags: ['Software development', 'Tools'],
    },
    {
      id: 4,
      img: '../../../Assets/Img/png/blog-Page-Image2.png',
      author: 'Aaron Chea',
      publishedDate: new Date(),
      title: 'Bill Walsh leadership lessons',
      content:
        'Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?',
      tags: ['Leadership', 'Management'],
    },
    {
      id: 5,
      img: '../../../Assets/Img/png/blog-Page-Image4.png',
      author: 'Shahriar Ahmed',
      publishedDate: new Date(),
      title: 'What is wireframing?',
      content:
        'Introduction to Wireframing and its Principles. Learn from the best in the industry.',
      tags: ['Design', 'Research'],
    },
  ];
  const blogPageDetailsHeroData = [
    {
      image: '../../../Assets/Img/png/blog_page_details-header-bg.png',
      nameAndDate: 'Olivia Rhye • 20 Jan 2024',
      title: 'UX review presentations',
      subTitle:
        'How do you create compelling presentations that wow your colleagues and impress your managers? Find out with our in-depth guide on UX presentations.',
      category: [{ cat1: 'Design', cat2: 'Research', cat3: 'Presentation' }],
    },
  ];

  const BlogPageDetailsData = {
    title1: 'Introduction',
    title1SubTitle1:
      'Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien varius id.',
    title1SubTitle2:
      'Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat. ',
    image: [
      '../../../Assets/Img/png/blog-page-details-other-resources-img.png',
      '../../../Assets/Img/png/blog-page-details-introduction-img.png',
    ],
    title1SubTitle3:
      'Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.',

    writerTextData: {
      title:
        '“In a world older and more complete than ours they move finished and complete, gifted with extensions of the senses we have lost or never attained, living by voices we shall never hear.”',
      deg: 'Olivia Rhye, Product Designer',
    },

    title1SubTitle4:
      'Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.',
    title1SubTitle5:
      'Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.',
    title1SubTitle6:
      'Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.',

    title2: 'Software and tools',
    title2SubTitle1:
      'Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien varius id.',
    title2SubTitle2:
      'Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat. ',

    title3: 'Other resources',
    title3SubTitle1:
      'Sagittis et eu at elementum, quis in. Proin praesent volutpat egestas sociis sit lorem nunc nunc sit. Eget diam curabitur mi ac. Auctor rutrum lacus malesuada massa ornare et. Vulputate consectetur ac ultrices at diam dui eget fringilla tincidunt. Arcu sit dignissim massa erat cursus vulputate gravida id. Sed quis auctor vulputate hac elementum gravida cursus dis.',
    title3SubTitle1List: [
      '1. Lectus id duis vitae porttitor enim gravida morbi.',
      '2. Eu turpis posuere semper feugiat volutpat elit, ultrices suspendisse. Auctor vel in vitae placerat.',
      '3. Suspendisse maecenas ac donec scelerisque diam sed est duis purus.',
    ],
    title3SubTitle2:
      'Lectus leo massa amet posuere. Malesuada mattis non convallis quisque. Libero sit et imperdiet bibendum quisque dictum vestibulum in non. Pretium ultricies tempor non est diam. Enim ut enim amet amet integer cursus. Sit ac commodo pretium sed etiam turpis suspendisse at.',
    title3SubTitle3:
      'Tristique odio senectus nam posuere ornare leo metus, ultricies. Blandit duis ultricies vulputate morbi feugiat cras placerat elit. Aliquam tellus lorem sed ac. Montes, sed mattis pellentesque suscipit accumsan. Cursus viverra aenean magna risus elementum faucibus molestie pellentesque. Arcu ultricies sed mauris vestibulum.',
    writer: {
      image: '../../../Assets/Img/png/blog-page-details-data-writer-img.png',
      name: 'Elina Rhye',
      deg: 'Product Designer, Explrar',
    },
  };
  return (
    <>
      <main>
        <section className="blog-page-details-container">
          {blogPageDetailsHeroData.map((detailsData, ind) => (
            <div key={ind}>
              <div className="blog-page-details-header-data-container">
                <p className="blog-page-details-name-and-data-text">
                  {detailsData?.nameAndDate}
                </p>
                <h1 className="blog-page-details-title-text">
                  {detailsData?.title}
                </h1>
                <p className="blog-page-details-subtitle-text">
                  {detailsData?.subTitle}
                </p>

                {detailsData.category.map((cat, ind) => (
                  <div className="blog-page-details-btn-container" key={ind}>
                    <button className="blog-page-details-btn">
                      {cat?.cat1}
                    </button>
                    <button className="blog-page-details-btn">
                      {cat?.cat2}
                    </button>
                    <button className="blog-page-details-btn">
                      {cat?.cat3}
                    </button>
                  </div>
                ))}
              </div>
              <img src={detailsData?.image} alt="" />
            </div>
          ))}
        </section>

        <section className="blog-page-details-data-container">
          <article>
            <section>
              <h3 className="blog-page-details-data-title1-text">
                {BlogPageDetailsData.title1}
              </h3>
              <p className="blog-page-details-subTitle-data">
                {BlogPageDetailsData.title1SubTitle1}
              </p>
              <br />
              <p className="blog-page-details-subTitle-data">
                {BlogPageDetailsData.title1SubTitle2}
              </p>
              <img
                style={{ margin: '48px 0' }}
                src={BlogPageDetailsData.image[0]}
                alt=""
              />
              <p className="blog-page-details-subTitle-data">
                {BlogPageDetailsData.title1SubTitle3}
              </p>

              <section
                style={{ margin: '50px 0', display: 'flex', gap: '20px' }}
              >
                <div style={{ borderLeft: '3px solid #FDA400' }}></div>
                <div>
                  <p
                    style={{
                      fontStyle: 'italic',
                      fontSize: '24px',
                      fontWeight: '500',
                      color: '#101012',
                    }}
                  >
                    {BlogPageDetailsData.writerTextData.title}
                  </p>
                  <p
                    style={{ marginTop: '32px' }}
                    className="blog-page-details-subTitle-data"
                  >
                    —{BlogPageDetailsData.writerTextData.deg}
                  </p>
                </div>
              </section>

              <p className="blog-page-details-subTitle-data">
                {BlogPageDetailsData.title1SubTitle4}
              </p>
              <br />
              <p className="blog-page-details-subTitle-data">
                {BlogPageDetailsData.title1SubTitle5}
              </p>
              <br />
              <p className="blog-page-details-subTitle-data">
                {BlogPageDetailsData.title1SubTitle6}
              </p>
            </section>

            <section>
              <h2 className="blog-page-details-data-title2-text">
                {BlogPageDetailsData.title2}
              </h2>
              <p className="blog-page-details-subTitle-data">
                {BlogPageDetailsData.title2SubTitle1}
              </p>
              <br />
              <p className="blog-page-details-subTitle-data">
                {BlogPageDetailsData.title2SubTitle2}
              </p>
            </section>

            <section>
              <h2 className="blog-page-details-data-title2-text">
                {BlogPageDetailsData.title3}
              </h2>
              <p className="blog-page-details-subTitle-data">
                {BlogPageDetailsData.title3SubTitle1}
              </p>
              <br />

              <ol
                style={{ display: 'flex', flexDirection: 'column', gap: '7px' }}
              >
                <li className="blog-page-details-subTitle-data">
                  {BlogPageDetailsData.title3SubTitle1List[0]}
                </li>
                <li className="blog-page-details-subTitle-data">
                  {BlogPageDetailsData.title3SubTitle1List[1]}
                </li>
                <li className="blog-page-details-subTitle-data">
                  {BlogPageDetailsData.title3SubTitle1List[2]}
                </li>
              </ol>

              <img
                style={{
                  margin: '48px 0',
                }}
                src={BlogPageDetailsData.image[1]}
                alt=""
              />
              <p className="blog-page-details-subTitle-data">
                {BlogPageDetailsData.title3SubTitle2}
              </p>
              <br />
              <p className="blog-page-details-subTitle-data">
                {BlogPageDetailsData.title3SubTitle3}
              </p>
            </section>
            <hr style={{ marginTop: '48px' }} />
            <section
              style={{
                display: 'flex',
                gap: '16px',
                color: '#101012',
                marginTop: '24px',
              }}
            >
              <img src={BlogPageDetailsData.writer.image} alt="" />
              <div>
                <p
                  style={{
                    fontSize: '18px',
                    fontWeight: '600',
                    marginBottom: '5px',
                  }}
                >
                  {BlogPageDetailsData.writer.name}
                </p>
                <p>{BlogPageDetailsData.writer.deg}</p>
              </div>
            </section>
          </article>
        </section>

        <section className="explrar-story-blog">
          <h1>From The Blog</h1>
          <p>
            The Latest our company news, technologies, and resources from our
            team.
          </p>
          <SharedBlogCard blogs={blogs} viewBlog={viewBlog} />
          <div className="explrar-story-blog-hr-line"></div>
          <div className="explrar-story-blog-btn">
            <button onClick={() => setViewBlog(blogs.length)}>
              View all posts
            </button>
          </div>
        </section>
      </main>
    </>
  );
}
