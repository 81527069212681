function emailHide(input) {
  var a = input.split('@');
  var b = a[0];
  var newstr = '';
  for (var i in b) {
    if (i > 2 && i < b.length - 1) newstr += '*';
    else newstr += b[i];
  }
  return newstr + '@' + a[1];
}
export default emailHide;
