import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';

export default function OpenFaqAccordion({
  item,
  expandedIndex,
  handleAccordionChange,
  setClicked,
  isClicked,
}) {
  return (
    <Accordion
      key={item?.id}
      square={true}
      defaultExpanded
      sx={{ boxShadow: 'none', marginBottom: '17px', width: '540px' }}
      // expanded={expandedIndex === item?.id}
      onChange={handleAccordionChange(item?.id)}
    >
      <AccordionSummary
        onClick={() => setClicked(false)}
        expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
        aria-controls={`panel${item?.id}-content`}
        id={`panel${item?.id}-header`}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: '17px',
            fontWeight: 600,
          }}
        >
          {item.headerTitle}
        </Typography>
      </AccordionSummary>

      <AccordionDetails style={{ width: '100%', margin: '0 auto' }}>
        <div className="customer-Support-Faq-container">
          <div>
            <p className="customer-support-faq-subTitle">{item.subTitle}</p>
            <p className="customer-support-faq-subTitleText">
              {item.subTitleText}
            </p>
            <p className="customer-support-faq-subTitleTextSub">
              {item.subTitleTextSub}
            </p>
          </div>

          <div className="customer-Support-Faq-button-Container">
            <a href="#">
              <Button
                sx={{ backgroundColor: '#FDA400' }}
                size="small"
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<HeadsetMicIcon sx={{ borderRadius: '4px' }} />}
              >
                Contact Support
              </Button>
            </a>
            <a href="mailto:support@explrar.com">
              <Button
                sx={{ backgroundColor: '#FDA400' }}
                size="small"
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<MailOutlineIcon sx={{ borderRadius: '5px' }} />}
              >
                Email Us
              </Button>
            </a>
            <a href="tel:+65 83715553">
              <Button
                sx={{ backgroundColor: '#FDA400' }}
                size="small"
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CallIcon sx={{ borderRadius: '5px' }} />}
              >
                Call Us
              </Button>
            </a>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
