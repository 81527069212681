import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Faq() {
  const data = [
    {
      id: 1,
      default: true,
      question: 'What is AR Explrar',
      answer:
        'AR Explrar is a feature of the Explrar app that uses Augmented Reality (AR) to enhance your travel experiences. It overlays digital information onto your real-world view, providing interactive navigation, attraction information, language translation, and more.',
    },
    {
      id: 2,
      question: 'How does AR Explrar work?',
      answer:
        "AR Explrar works by overlaying digital data onto the live feed from your smartphone's camera. When you point your camera at certain objects or locations, the app provides information, directions, translations, or interactive experiences related to what you're viewing.",
    },
    {
      id: 3,
      question: ' What features does AR Explrar offer?',
      answer:
        'AR Explrar offers AR Navigation, Interactive Attraction Information, AR City Tours, Live Language Translation, Augmented Reality Hotel Previews, Interactive Public Transportation, AR-enabled Airport Navigation, Voice Commands and Gesture Controls, and Social Sharing.',
    },
    {
      id: 4,
      question: 'Do I need any special equipment to use AR Explrar?',
      answer:
        " No, all you need is a smartphone with the Explrar app installed. AR Explrar uses your phone's built-in camera and sensors to deliver its AR features",
    },
    {
      id: 5,
      question: 'Is AR Explrar difficult to use?',
      answer:
        "Not at all! We've designed AR Explrar to be as user-friendly as possible. Even if you're new to AR, you should find it intuitive and easy to use.",
    },
    {
      id: 6,
      question: 'Can I use AR Explrar anywhere in the world?',
      answer:
        'Yes! As long as you have your smartphone and the Explrar app, you can use AR Explrar wherever your travels take you.',
    },
    {
      id: 7,
      question: 'Does using AR Explrar consume a lot of data or battery power?',
      answer:
        'AR applications can be more data and power-intensive than non-AR apps due to their interactive and real-time nature. We recommend ensuring you have a suitable data plan and a fully charged battery when using AR Explrar for extended periods.',
    },
    {
      id: 8,
      question: 'Is AR Explrar available in my language?',
      answer:
        ' Yes, AR Explrar supports multiple languages for its interface and translation feature.',
    },
  ];
  return (
    <div className="faq-main" style={{ fontFamily: 'Poppins, sans-serif' }}>
      <h1 style={{ marginBottom: '71px' }}>Frequently Asked Questions</h1>
      {data.map((item, idx) => (
        <Accordion
          defaultExpanded={item.default}
          style={{ fontFamily: 'Poppins, sans-serif' }}
          key={idx}
          square={true}
          sx={{ boxShadow: 'none', marginBottom: '35px' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
            aria-controls={item?.id}
            id={item?.id}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: '20px',
                fontWeight: 500,
              }}
            >
              {item.id + '. ' + item?.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              width: '100%',
              margin: '0 auto',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: '16px',
                lineHeight: '29px ',
              }}
            >
              {item?.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
