import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Triangle } from 'react-loader-spinner';
import { currencyCodes } from '../../services/service';
import { setSupportedCurrencies } from '../../redux/userState';

const JSON = require('../../data/static.json');
export default function CurrencyPopup({
  setCurrencyHandle,
  open,
  handleClose,
}) {
  const prefered_currency = useSelector((state) => state.user.preferedCurrency);
  const supportedCurrencies = useSelector(
    (state) => state.user.supportedCurrencies
  );
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchSupportedCurrencies = async () => {
      let currencies = [];
      try {
        const response = await currencyCodes();

        if (!response) {
          return;
        }

        if (response.supported_codes) {
          const data = response.supported_codes;
          for (let item of data) {
            currencies.push({
              code: item[0],
              name: item[1],
            });
          }
          dispatch(setSupportedCurrencies(currencies));
        }
      } catch (error) {
        console.log('Failed to fetch the supported currencies: ', error);
      }
    };
    if (supportedCurrencies.length === 0) {
      fetchSupportedCurrencies();
    }
  }, [supportedCurrencies]);
  return (
    <React.Fragment>
      <Dialog fullWidth={false} maxWidth="lg" open={open} onClose={handleClose}>
        <div className="currency-popup-container">
          <p className="">Currency</p>
          <DialogActions>
            <Button onClick={handleClose}>
              <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 37.5L37.5 12.5M12.5 12.5L37.5 37.5"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button>
          </DialogActions>
        </div>
        <DialogTitle className="currency-title-container">
          <p>Popular Currencies</p>
        </DialogTitle>
        <div className="popular-currency-container">
          {JSON.currency?.map((item) => {
            return (
              <div
                key={item.code}
                className={`each-currency ${prefered_currency === item.code ? 'active-currency-code' : ''}`}
                onClick={() => setCurrencyHandle(item.code)}
              >
                <p>
                  {' '}
                  <span className="color-blue">{item.code}</span> - {item.name}
                </p>
              </div>
            );
          })}
        </div>
        <DialogTitle className="currency-title-container">
          <p>All Currencies</p>
        </DialogTitle>
        <div className="">
          <div className="all-currency-container">
            {supportedCurrencies === null ? (
              <Triangle
                height="30"
                width="30"
                color="#FDA400"
                ariaLabel="triangle-loading"
                wrapperStyle={{ justifyContent: 'space-around' }}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              supportedCurrencies.map((item) => {
                return (
                  <div
                    key={item.code}
                    className={`each-currency ${prefered_currency === item.code ? 'active-currency-code' : ''}`}
                    onClick={() => setCurrencyHandle(item.code)}
                  >
                    <p>
                      {' '}
                      <span className="color-blue">{item.code}</span> -{' '}
                      {item.name}
                    </p>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
