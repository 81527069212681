import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import emailHide from '../../utils/mail/emailhide';
import { userResendOTP } from '../../redux/userState';
import { Triangle } from 'react-loader-spinner';

export default function ResetOtpVerify({ dispatchFn, email, type }) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(59);
  const [showSend, setShowSend] = useState(false);
  const [otp, setOtp] = useState({ otp1: '', otp2: '', otp3: '', otp4: '' });
  const otpHandler = (e) => {
    setOtp({ ...otp, [e.target.name]: e.target.value });
  };

  // otp input handler
  const inputfocus = (elmnt) => {
    if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 5) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  // verify OTP
  const verifyOTP = async (e) => {
    e.preventDefault();
    const userOTP = otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4;
    const json = {
      userId: user.userOTP.data ? user.userOTP.data.userId : '',
      otp: userOTP,
      access_token: localStorage.getItem('access_token'),
    };
    dispatch(dispatchFn(json));
  };

  // resend OTP
  const resendOTP = async (e) => {
    e.preventDefault();
    const json = {
      userId: user.userOTP.data ? user.userOTP.data.userId : '',
      email: email,
      type,
    };

    setTimer(59);
    setShowSend(false);
    dispatch(userResendOTP(json));
  };

  useEffect(() => {
    let interval = null;
    if (user.isOTPMailSent || user.isEmailUpdateOTPSent) {
      interval = setInterval(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    }
    if (timer <= 0) {
      clearInterval(interval);
      setShowSend(true);
    }
    return () => clearInterval(interval);
  }, [timer, user]);

  return (
    <div className="px-3 mt-3">
      <h1 className="fs_lg fw_xtr_bold ff_inter color_blue_black text-center">
        Check Your E-mail
      </h1>
      <p className="fs_sm fw_normal ff_inter color_blue_black text-center mt-3 pt-1 mb-0">
        We've sent a 4-digit OTP to your email
      </p>
      <p className="fs_sm fw_normal ff_inter color_blue_black text-center mt-3 pt-1 mb-0">
        {emailHide(email)}
      </p>
      <form action="">
        <div className="code-inputs d-flex align-items-center justify-content-evenly mt-4 pt-3">
          <input
            name="otp1"
            type="number"
            maxLength="1"
            placeholder="-"
            value={otp.otp1}
            onChange={(e) => {
              if (e.target.value.length > 1)
                e.target.value = e.target.value.slice(0, 1);
              otpHandler(e);
            }}
            tabIndex="1"
            onKeyUp={(e) => inputfocus(e)}
            className="form-control-code otp_border fs_sm fw_normal ff_inter color_blue_black w_99"
          />
          <input
            name="otp2"
            type="number"
            maxLength="1"
            placeholder="-"
            value={otp.otp2}
            onChange={(e) => {
              if (e.target.value.length > 1)
                e.target.value = e.target.value.slice(0, 1);
              otpHandler(e);
            }}
            tabIndex="2"
            onKeyUp={(e) => inputfocus(e)}
            className="form-control-code otp_border fs_sm fw_normal ff_inter color_blue_black w_99"
          />
          <input
            name="otp3"
            type="number"
            maxLength="1"
            placeholder="-"
            value={otp.otp3}
            onChange={(e) => {
              if (e.target.value.length > 1)
                e.target.value = e.target.value.slice(0, 1);
              otpHandler(e);
            }}
            tabIndex="3"
            onKeyUp={(e) => inputfocus(e)}
            className="form-control-code otp_border fs_sm fw_normal ff_inter color_blue_black w_99"
          />
          <input
            name="otp4"
            type="number"
            maxLength="1"
            placeholder="-"
            value={otp.otp4}
            onChange={(e) => {
              if (e.target.value.length > 1)
                e.target.value = e.target.value.slice(0, 1);
              otpHandler(e);
            }}
            tabIndex="4"
            onKeyUp={(e) => inputfocus(e)}
            className="form-control-code otp_border fs_sm fw_normal ff_inter color_blue_black w_99"
          />
        </div>

        <p className="fs_sm fw_normal ff_inter color_gray text-end mt-3 pt-1 mb-2 pb-1 Resend-otp">
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'baseline',
            }}
            className="gap-2"
          >
            Didn’t get a code? 00:{timer.toString().padStart(2, '0')}
            {showSend ? (
              <a className="color_blue fw_medium" onClick={resendOTP}>
                Resend
              </a>
            ) : (
              <div className="fs_sm fw_normal ff_inter color_gray text-end mt-3 pt-1 mb-2 pb-1">
                Resend
              </div>
            )}
          </div>
        </p>
        <div className="d-flex mt-4">
          <button
            className="login_btn w-100 fs_sm fw_bold ff_inter color_white"
            onClick={verifyOTP}
          >
            {user.isLoading ? (
              <Triangle
                height="30"
                width="30"
                color="#FDA400"
                ariaLabel="triangle-loading"
                wrapperStyle={{ justifyContent: 'space-around' }}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              'Verify'
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
