import React from 'react';
import './DownloadApp.css';

export default function DownloadAppGersStarted() {
  return (
    <>
      <div className="get-started-section-sub-data-info-container">
        <div>
          <h3 className="get-started-section-data-title-text">
            Download The Explrar App
          </h3>
          <p className="get-started-section-sub-data-title-text">
            Download the Explrar app from the Google Play Store or Apple App
            Store.
          </p>
        </div>
        <div>
          <h3 className="get-started-section-data-title-text">
            Create An Account{' '}
          </h3>
          <p className="get-started-section-sub-data-title-text">
            Sign up to access your personalized travel dashboard.
          </p>
        </div>
        <div>
          <h3 className="get-started-section-data-title-text">
            Start Explr-ing With Explrar
          </h3>
          <p className="get-started-section-sub-data-title-text">
            Start planning your next journey with ease.
          </p>
        </div>
      </div>
    </>
  );
}
