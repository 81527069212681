import React, { useState } from 'react';
import './ourStory.css';
import SharedBlogCard from '../sharedBlogCard';
import { Helmet } from 'react-helmet';

export default function OurStory() {
  const [viewBlog, setViewBlog] = useState(3);
  const blogs = [
    {
      id: 1,
      img: '../../../Assets/Img/png/blog-Page-Image6.png',
      author: 'Olivia Rhye',
      publishedDate: new Date(),
      title: 'UX review presentations',
      content:
        'How do you create compelling presentations that wow your colleagues and impress your managers?',
      tags: ['Product', 'Research', 'Presentation'],
    },
    {
      id: 2,
      img: '../../../Assets/Img/png/blog-Page-Image.png',
      author: 'Phoenix Baker',
      publishedDate: new Date(),
      title: 'Migrating to Linear 101',
      content:
        'Linear helps streamline software projects, sprints, tasks, and bug tracking. Here’s how to get started.',
      tags: ['Product', 'Tools', 'SaaS'],
    },
    {
      id: 3,
      img: '../../../Assets/Img/png/blog-Page-Image1.png',
      author: 'Lana Steiner',
      publishedDate: new Date(),
      title: 'Building your API stack',
      content:
        'The rise of RESTful APIs has been met by a rise in tools for creating, testing, and managing them.',
      tags: ['Software development', 'Tools'],
    },
    {
      id: 4,
      img: '../../../Assets/Img/png/blog-Page-Image2.png',
      author: 'Aaron Chea',
      publishedDate: new Date(),
      title: 'Bill Walsh leadership lessons',
      content:
        'Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?',
      tags: ['Leadership', 'Management'],
    },
    {
      id: 5,
      img: '../../../Assets/Img/png/blog-Page-Image4.png',
      author: 'Shahriar Ahmed',
      publishedDate: new Date(),
      title: 'What is wireframing?',
      content:
        'Introduction to Wireframing and its Principles. Learn from the best in the industry.',
      tags: ['Design', 'Research'],
    },
  ];
  return (
    <>
      <Helmet>
        <title>About Us - Discover Our Journey | Explrar</title>
        <meta
          name="description"
          content="Learn about Explrar's mission to simplify and enhance travel planning through technology. Our story began in 2023, driven by the passion to make travel accessible and enjoyable for everyone. Join us on our journey!"
        />
      </Helmet>
      <main>
        <section className="our-story-hero">
          <div className="our-story-hero-content">
            <img
              src="../../../Assets/Img/png/white-transparent-explrar.png"
              alt=""
            />
            <h1>Simplify Travel, Amplify Experiences.</h1>
          </div>
        </section>
        <section className="our-story-content">
          <div className="our-story-content-text">
            <h1>Our Story</h1>
            <p>
              Welcome to Explrar, your gateway to discovering the world.
              Launched in 2023, our mission has been clear from the start to
              simplify travel for everyone by merging comprehensive travel
              solutions into one accessible platform.
            </p>
          </div>
        </section>
        <section className="our-story-facts-about-explrar">
          <div className="our-story-facts-about-explrar-container">
            <h1>Facts About Explrar</h1>
            <div className="our-story-facts-about-explrar-container-box">
              <div className="our-story-facts-about-explrar-container-item">
                <h1>2023</h1>
                <h4>Founded</h4>
                <p>
                  Explrar was founded in 2023 with an idea to simplify travel
                  and amplify experience.
                </p>
              </div>
              <div className="our-story-facts-about-explrar-container-item">
                <h1>1,200+</h1>
                <h4>Airlines Available</h4>
                <p>
                  We offer flights of over 1,200 airlines throughout the world.
                </p>
              </div>
              <div className="our-story-facts-about-explrar-container-item">
                <h1>10,000+</h1>
                <h4>Cities for Trip Plans</h4>
                <p>
                  Looking to travel somewhere? We can generate trip plans to
                  almost anywhere in the world.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div>
            <div className="explrar-story-about">
              <h1>Foundation and Vision</h1>
              <p>
                The idea for Explrar was born out of a desire to streamline the
                travel planning process, eliminating the complexities that
                travelers often face. Our platform is designed to empower
                adventurers by offering everything from flight bookings to hotel
                reservations and unique local experiences—all in one place.
              </p>
            </div>
          </div>
          <div className="explrar-story-item">
            <div className="explrar-story-about">
              <h1>Growth and Development</h1>
              <p>
                Since our inception, we have focused on enhancing our platform's
                capabilities and extending our service offerings. Each feature
                has been developed with the user in mind, ensuring ease of use,
                reliability, and efficiency. Today, Explrar not only helps you
                book your travel but also inspires and facilitates smoother,
                more enjoyable travel experiences.
              </p>
            </div>
          </div>
          <div>
            <div className="explrar-story-about">
              <h1>Community Engagement</h1>
              <p>
                Explrar is committed to more than just travel; we are dedicated
                to making a positive impact on the communities we serve. We
                actively promote sustainable travel practices that respect and
                preserve the cultural and natural heritage of our destinations.
              </p>
            </div>
          </div>
          <div className="explrar-story-item">
            <div className="explrar-story-about">
              <h1>Looking Forward</h1>
              <p>
                As we move forward, Explrar continues to innovate, driven by our
                passion for travel and technology. We are constantly improving
                our platform to adapt to the changing needs of travelers
                worldwide. Whether you're booking a short city break or planning
                an extensive overseas journey, Explrar is here to ensure that
                your travel is as stress-free as the destination is rewarding.
              </p>
            </div>
          </div>
        </section>
        <section className="explrar-story-blog">
          <h1>From The Blog</h1>
          <p>
            The Latest our company news, technologies, and resources from our
            team.
          </p>
          <SharedBlogCard blogs={blogs} viewBlog={viewBlog} />
          <div className="explrar-story-blog-hr-line"></div>
          <div className="explrar-story-blog-btn">
            <button onClick={() => setViewBlog(blogs.length)}>
              View all posts
            </button>
          </div>
        </section>
      </main>
    </>
  );
}
