import React from 'react';
import Faq from './faq';
import ArExplrarWork from './arExplrarWork';
import { Helmet } from 'react-helmet';

// import ArExplrarWork from './arExplrarWork'

export default function ArExplrar() {
  // const hearWhats = [
  //   {
  //     image: star_img,
  //     title:
  //       'Lorem ipsum dolor sit amet consectetur. Leo amet in maecenas platea. Sollicitudin diam quis cras mattis id. Mauris euismod et auctor viverra gravida.',
  //     name: 'Jhon Doe',
  //     Travel: 'Traveler',
  //     profile_image: iphone14
  //   },
  //   {
  //     image: star_img,
  //     title:
  //       'Lorem ipsum dolor sit amet consectetur. Leo amet in maecenas platea. Sollicitudin diam quis cras mattis id. Mauris euismod et auctor viverra gravida.',
  //     name: 'Jhon Doe',
  //     Travel: 'Traveler',
  //     profile_image: frofile2_img
  //   },
  //   {
  //     image: star_img,
  //     title:
  //       'Lorem ipsum dolor sit amet consectetur. Leo amet in maecenas platea. Sollicitudin diam quis cras mattis id. Mauris euismod et auctor viverra gravida.',
  //     name: 'Jhon Doe',
  //     Travel: 'Traveler',
  //     profile_image: frofile3_img
  //   },
  //   {
  //     image: star_img,
  //     title:
  //       'Lorem ipsum dolor sit amet consectetur. Leo amet in maecenas platea. Sollicitudin diam quis cras mattis id. Mauris euismod et auctor viverra gravida.',
  //     name: 'Jhon Doe',
  //     Travel: 'Traveler',
  //     profile_image: frofile4_img
  //   }
  // ]

  const arExplrarWork = [
    {
      headerTitle: 'Launch AR Explrar:',
      headerSubTitle: 'Open up the AR Explrar feature in the Explrar app.',
      work_Image: '../../Assets/Img/iPhone 14 Pro Space Black Mockup (1).png',
    },
    {
      headerTitle: 'Point and Explore:',
      headerSubTitle: 'Open up the AR Explrar feature in the Explrar app.',
      work_Image: '../../Assets/Img/iPhone 14 Pro Space Black Mockup (2).png',
    },
    {
      headerTitle: 'Instant Information:',
      headerSubTitle: 'Open up the AR Explrar feature in the Explrar app.',
      work_Image: '../../Assets/Img/iPhone 14 Pro Space Black Mockup (3).png',
    },
    {
      headerTitle: 'AR Navigation:',
      headerSubTitle: 'Open up the AR Explrar feature in the Explrar app.',
      work_Image: '../../Assets/Img/iPhone 14 Pro Space Black Mockup (4).png',
    },
    {
      headerTitle: 'Live Language Translation:',
      headerSubTitle: 'Open up the AR Explrar feature in the Explrar app.',
      work_Image: '../../Assets/Img/iPhone 14 Pro Space Black Mockup (5).png',
    },
  ];

  const whyUses = [
    {
      image: '../../Assets/Img/learning-img.svg',
      title: 'Interactive Learning',
      subTitle:
        'Make learning about your travel destinations a fun and interactive experience with quizzes, trivia, and more.',
    },
    {
      image: '../../Assets/Img/location-img.svg',
      title: 'Enhanced Navigation',
      subTitle:
        'No more struggling with maps! Use AR Explrar for real-time, intuitive directions that make getting around a breeze.',
    },
    {
      image: '../../Assets/Img/hello-img.svg',
      title: 'Language Translation',
      subTitle:
        'Overcome language barriers with instant AR translations of signs, menus, and other written materials.',
    },
    {
      image: '../../Assets/Img/personalized-img.svg',
      title: 'Personalized Experiences',
      subTitle:
        'Receive personalized recommendations based on your preferences and travel history.',
    },
    {
      image: '../../Assets/Img/time-img.svg',
      title: 'Real-time Information',
      subTitle:
        'Access up-to-date information about public transportation, flight updates, and more, right in the moment you need it.',
    },
    {
      image: '../../Assets/Img/ease-img.svg',
      title: 'Ease of Use',
      subTitle:
        'User-friendly interface makes it easy even for first-time AR users to navigate and interact with the feature.',
    },
  ];
  return (
    <>
      <Helmet>
        <title>AR Explrar: Augmented Reality Travel Experience</title>
        <meta
          name="description"
          content="Enhance your travel with AR Explrar, our augmented reality platform that brings destinations to life. Discover interactive maps, live navigation, and immersive content that transform your travel experiences. Explore the future of travel with AR Explrar today!"
        />
      </Helmet>
      {/* // -------Ar bg section start ------- */}
      <div style={{ marginTop: '0px' }}>
        <section className="ar-hero-section">
          <div className="ar-hero-container">
            <div
              className="ar-hero-data-info"
              style={{
                position: 'relative',
              }}
            >
              <div className="coming-soon">
                <img
                  src="../../Assets/icons/blue-star.svg"
                  alt=""
                  className="blue-star"
                />
                <span className="color-blue">Feature Coming Soon</span>
              </div>
              <h2 className="ar-hero-title">
                <span className="text-color">Experience Travel Like </span>{' '}
                Never Before with
              </h2>
              <h2 className="text-color ar-hero-title">AR Explrar</h2>

              <div className="augment-text">
                <h4>Augment your reality, enrich your journeys</h4>
              </div>

              <div className="app-store-google-play">
                <div>
                  <img src="../../Assets/Img/image 8.png" alt="" />
                </div>
                <div>
                  <img src="../../Assets/Img/image 9.png" alt="" />
                </div>
              </div>
            </div>
            <div>
              <img
                cl
                className="ar-hero-img"
                src="../../Assets/Img/ar-hero-group.png"
                alt=""
              />
            </div>
          </div>
        </section>
        {/* // --------Ar bg section End --------*/}

        <section className="ar-ecplrar-main">
          {/*------------ whate is ar explrar  section start ---------- */}

          <div className="ar-explrar-container">
            <div className="ar-explrar-container-info">
              <img
                className="frame-img"
                src="../../Assets/Img/Frame 1000003050.png"
                alt=""
              />
            </div>

            <div className="ar-explrar-container-info">
              <h1 className="ar-explrar-data-info">
                What is <span className="text-color">AR Explrar?</span>
              </h1>

              <p className="ar-explror-data-sub-info">
                Augmented Reality, or AR, is a game-changing technology that
                overlays digital information, like images and text, onto your
                real-world view. With AR, your smartphone's camera becomes a
                window to an enriched, interactive version of reality.
              </p>
              <br />
              <p className="ar-explror-data-sub-info">
                AR Explrar, Explrar's specialized AR feature, transforms your
                travel experiences. Instead of looking down at a map or a
                guidebook, you can now engage with your environment directly.
                From real-time navigation to instant information about
                landmarks, AR Explrar makes your travel more informative,
                immersive, and fun.
              </p>
            </div>
          </div>
        </section>
        {/*------------ whate is ar explrar  section End ---------- */}

        {/*------------ How Does AR Explrar Work section start ------------*/}
        <section>
          <h3 className="ar-exploar-work-header">
            How Does <span className="text-color">AR Explrar</span> Work?
          </h3>
          <ArExplrarWork arExplrarWork={arExplrarWork} />
        </section>
        {/*------------ How Does AR Explrar Work section End ------------*/}

        {/*------------ Why Use AR Explrar? section Start ---------- */}
        <section className="why-uses-all-data-container">
          <div className="why-uses-data-container">
            <div>
              <p className="why-uses-title-info text-color satisfy-regular">
                Benefits
              </p>
              <h1 className="why-uses-header-title">
                Why Use <span className="text-color">AR Explrar?</span>
              </h1>
            </div>

            <div className="why-uses-container">
              {whyUses.map((use, ind) => (
                <div key={ind}>
                  <div>
                    <img src={use.image} alt="" />
                  </div>
                  <div>
                    <p className="why-uses-title">{use.title}</p>
                  </div>
                  <div>
                    <p className="why-uses-title-info">{use.subTitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="why-uses-ellipse-icon">
            <img src="../../Assets/Img/Ellipse 624.svg" alt="" />
          </div>
        </section>
        {/*------------ Why Use AR Explrar? section End ---------- */}

        {/*------------ Hear What Others Say section start ---------- */}
        {/* <section>
          <div className="hear-what-header-data">
            <h3 className="hear-what-info-data text-color satisfy-regular">Testimonials</h3>
            <h1 className="hear-what-header-data-title">Hear What Others Say</h1>
          </div>
          <div className="hear-what-all-container">
            {hearWhats.map((hearWhat, index) => (
              <div className="hear-what-data-container" key={index}>
                <div className="hear-what-sec1">
                  <img src={hearWhat.image} alt="" />
                  <p className="hear-what-title">{hearWhat.title}</p>
                </div>

                <div>
                  <p className="hear-what-name">{hearWhat.name}</p>
                  <p className="hear-what-travel-title">{hearWhat.Travel}</p>
                  <img className="hear-what-profile_image" src={hearWhat.profile_image} alt="" />
                </div>
              </div>
            ))}
          </div>
        </section> */}
        {/*------------ Hear What Others Say section End ---------- */}

        {/* Download AR Explrar Now section start*/}

        <section>
          <div className="future-of-travel-container">
            <div className="future-of-travel-title-container">
              <div className="future-of-travel-title">
                <h1>Step into the Future of Travel</h1>
                <h1 className="text-color">Download AR Explrar Now</h1>
                <span className="future-of-travel-ellipse_icon1">
                  <img src="../../Assets/Img/Ellipse 612.png" alt="" />
                </span>
                <span className="future-of-travel-ellipse_icon2">
                  <img src="../../Assets/Img/Ellipse 613.png" alt="" />
                </span>
              </div>

              <div className="future-of-travel-app-google-img">
                <img src="../../Assets/Img/image 8.png" alt="" />
                <img src="../../Assets/Img/image 9.png" alt="" />
              </div>
            </div>
            <div className="future-of-travel-img">
              <img
                src="../../Assets/Img/iPhone 14 Pro Space Black Mockup (1).png"
                alt=""
              />
            </div>
          </div>
        </section>
        {/* Download AR Explrar Now section End*/}

        {/* FAQ Section start */}

        <section className="faq-container">
          <Faq />
        </section>
      </div>
    </>
  );
}
