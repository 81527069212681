import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';

export default function FaqAccordion({
  expandedIndex,
  items,
  handleAccordionChange,
  setIsClicked,
  isClicked,
  setClickedItem,
  clickedItem,
}) {
  return items.map((item) => (
    <Accordion
      key={item?.id}
      square={true}
      sx={{
        boxShadow: 'none',
        marginBottom: '17px',
        width: isClicked && clickedItem ? '550px' : '550px',
        margin: '0 auto',
        height: '83px',
        padding: '20px 0',
      }}
      expanded={false}
      onChange={handleAccordionChange(item?.id)}
    >
      <AccordionSummary
        onClick={() => {
          setClickedItem(item);
          setIsClicked(true);
        }}
        expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
        aria-controls={`panel${item?.id}-content`}
        id={`panel${item?.id}-header`}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: '17px',
            fontWeight: 600,
          }}
        >
          {item?.headerTitle}
        </Typography>
      </AccordionSummary>

      <AccordionDetails style={{ width: '100%', margin: '0 auto' }}>
        <div className="customer-Support-Faq-container">
          <div>
            <p className="customer-support-faq-subTitle">{item?.subTitle}</p>
            <p className="customer-support-faq-subTitleText">
              {item?.subTitleText}
            </p>
            <p className="customer-support-faq-subTitleTextSub">
              {item?.subTitleTextSub}
            </p>
          </div>

          <div className="customer-Support-Faq-button-Container">
            <Button
              sx={{ backgroundColor: '#FDA400' }}
              size="small"
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={
                <HeadsetMicIcon
                  sx={{ backgroundColor: '#FDA400', borderRadius: '4px' }}
                />
              }
            >
              Contact Support
            </Button>
            <Button
              sx={{ backgroundColor: '#FDA400' }}
              size="small"
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={
                <MailOutlineIcon
                  sx={{ backgroundColor: '#FDA400', borderRadius: '5px' }}
                />
              }
            >
              Email Us
            </Button>
            <Button
              sx={{ backgroundColor: '#FDA400' }}
              size="small"
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={
                <CallIcon
                  sx={{ backgroundColor: '#FDA400', borderRadius: '5px' }}
                />
              }
            >
              Call Us
            </Button>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  ));
}
