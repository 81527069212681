import React from 'react';
import './customerSupport.css';
import './customerSup.module.css';
// mui icons ----------->
import CallIcon from '@mui/icons-material/Call';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import BasicTabs from './customerSupportTabs';

const allImages = {
  customer_support_hero: '../../../Assets/Img/png/customer-support-hero.png',
};
export default function CustomerSupport() {
  return (
    <>
      <section className="cst-support-main-container">
        {/* customer-Support-hero-section-start */}
        <main>
          <section className="cst-support-hero-container">
            <div className="cst-support-hero">
              <div>
                <h1 className="cst-support-hero-title">
                  Always Here to Assist!
                </h1>
                <p className="cst-support-hero-sub-title">
                  Access instant help on your bookings, find quick answers to
                  your questions, or connect with our customer support team.
                </p>
              </div>
              <div>
                <img
                  className="cst-support-hero-image"
                  src={allImages?.customer_support_hero}
                  alt=""
                />
              </div>
            </div>
          </section>

          <section className="cst-support-hero-contact-all-data-container">
            <div className="cst-support-hero-contact-container">
              <div className="cst-support-hero-contact">
                <HeadsetMicIcon sx={{ color: 'white' }} />
                <p className="cst-support-hero-contact-title">Chat</p>
              </div>

              <div className="cst-support-hero-contact-line"></div>

              <div className="cst-support-hero-contact">
                <a href="tel:+65 83715553" className="cst-support-hero-contact">
                  <CallIcon sx={{ color: 'white' }} />
                  <p className="cst-support-hero-contact-title">Call Us</p>
                </a>
              </div>

              <div className="cst-support-hero-contact-line"></div>

              <div className="cst-support-hero-contact">
                <a
                  href="mailto:support@explrar.com"
                  className="cst-support-hero-contact"
                >
                  <MailOutlineIcon sx={{ color: 'white' }} />
                  <p className="cst-support-hero-contact-title">E-mail Us</p>
                </a>
              </div>
            </div>
          </section>
        </main>
        {/* customer-Support-hero-section-End */}

        {/* customer-Support-Main-section-start */}
        <main>
          <section className="cst-support-tabs-container">
            <BasicTabs />
          </section>
        </main>
        {/* customer-Support-Main-section-Ebd */}
      </section>

      {/* customer-Support-Footer-section-start */}
      {/* customer-Support-Footer-section-End */}
    </>
  );
}
