import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import CustomerSupportFaq from './customSupFaq';

// mui icons ----------->

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [isClicked, setIsClicked] = React.useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tabItems = [
    {
      label: 'Flights',
      content: [
        {
          id: 0,
          headerTitle: 'How can I book a flight on Explrar?',
          subTitle: 'Solution',
          subTitleText:
            "To book a flight, navigate to the 'Flights' tab on our platform. Enter your departure and arrival destinations, select your travel dates, and click 'Search'. Choose your preferred flight from the results, and proceed by entering passenger details and payment information.",
          subTitleTextSub: 'Can’t find Solution?',
        },
        {
          id: 1,
          headerTitle: 'How can I change or cancel my flight booking?',
          subTitle: 'Solution',
          subTitleText:
            "To change or cancel your flight booking, go to the 'Manage Bookings' section on our website or app. Locate your booking and select the option to modify or cancel. Please note that fees and conditions may apply, which vary by airline.",
          subTitleTextSub: 'Can’t find Solution?',
        },

        {
          id: 2,
          headerTitle: 'What documents do I need for my flight?',
          subTitle: 'Solution',
          subTitleText:
            'Convallis morbi sit amet diam et nec quam. Pretium placerat aliquet etiam tristique dolor nisi ullamcorper aenean. Sit ullamcorper nisl diam eget sed imperdiet viverra cursus.',
          subTitleTextSub: 'Can’t find Solution?',
        },
        {
          id: 3,
          headerTitle: 'Can I book a multi-city flight itinerary?',
          subTitle: 'Solution',
          subTitleText:
            'Yes, you can book multi-city flights by selecting the ‘Multi-city’ option in our search bar. Enter your desired cities and dates, and we’ll show you the best available routes.',
          subTitleTextSub: 'Can’t find Solution?',
        },
      ],
    },
    {
      label: 'Hotels',
      content: [
        {
          id: 0,
          headerTitle: 'How do I make a hotel booking?',
          subTitle: 'Solution',
          subTitleText:
            "To book a hotel, simply navigate to the 'Hotels' tab, enter your destination, check-in and check-out dates, and the number of guests. Then, search to view available hotels. Select your preferred hotel, choose a room, and proceed with the booking. ",
          subTitleTextSub: 'Can’t find Solution?',
        },
        {
          id: 1,
          headerTitle: 'What should I check before booking a hotel?',
          subTitle: 'Solution',
          subTitleText:
            'Before booking, consider the hotel’s location, amenities, guest reviews, and cancellation policy. Ensure it meets your needs, especially if you require free Wi-Fi, breakfast, or specific facilities like a pool or gym',
          subTitleTextSub: 'Can’t find Solution?',
        },
        {
          id: 2,
          headerTitle: 'How do I know if my hotel booking is confirmed?',
          subTitle: 'Solution',
          subTitleText:
            "Once your booking is completed, you will receive a confirmation email with your booking details and a confirmation number. You can also verify your booking status in the 'Manage Bookings' section on our platform.",
          subTitleTextSub: 'Can’t find Solution?',
        },
        {
          id: 3,
          headerTitle: 'What do I do if I need to cancel my hotel booking?',
          subTitle: 'Solution',
          subTitleText:
            "To cancel a hotel booking, go to 'Manage Bookings', select your booking, and choose the option to cancel. Cancellation policies vary by hotel, so it’s important to read the terms before proceeding. Some bookings may be non-refundable.",
          subTitleTextSub: 'Can’t find Solution?',
        },
      ],
    },
    {
      label: 'Trip Planner',
      content: [
        {
          id: 0,
          headerTitle: 'How do I use the trip planner to organize my travel?',
          subTitle: 'Solution',
          subTitleText:
            "Access the trip planner feature by navigating to the 'Trip Planner' section. Start by entering your destination, travel dates, and preferences. Our planner will suggest a personalized itinerary including flights, hotels, and activities based on your interests and budget.",
          subTitleTextSub: 'Can’t find Solution?',
        },
        {
          id: 1,
          headerTitle:
            'Can I customize the itinerary generated by the trip planner?',
          subTitle: 'Solution',
          subTitleText:
            'Absolutely! You can add, or remove activities and bookings to suit your schedule and preferences. The trip planner allows for complete customization to ensure your itinerary matches your expectations.',
          subTitleTextSub: 'Can’t find Solution?',
        },
        {
          id: 2,
          headerTitle:
            'Does the trip planner save my itineraries for future reference?',
          subTitle: 'Solution',
          subTitleText:
            'Yes, all your itineraries are saved under your account. You can access previous plans, make modifications, or share them with your friends and family.',
          subTitleTextSub: 'Can’t find Solution?',
        },
        {
          id: 3,
          headerTitle: 'Is there a fee to use the trip planner?',
          subTitle: 'Solution',
          subTitleText:
            'Our trip planner is a complimentary service provided to all users. We aim to enhance your travel experience by simplifying the planning process at no extra cost.',
          subTitleTextSub: 'Can’t find Solution?',
        },
      ],
    },
    // {
    //   label: 'Dining Deals',
    //   content: [
    //     {
    //       id: 0,
    //       headerTitle: 'How to change my Ticket? 1',
    //       subTitle: 'Solution',
    //       subTitleText:
    //         'Convallis morbi sit amet diam et nec quam. Pretium placerat aliquet etiam tristique dolor nisi ullamcorper aenean. Sit ullamcorper nisl diam eget sed imperdiet viverra cursus.',
    //       subTitleTextSub: 'Can’t find Solution?'
    //     },
    //     {
    //       id: 1,
    //       headerTitle: 'How to change my Ticket? 1',
    //       subTitle: 'Solution',
    //       subTitleText:
    //         'Convallis morbi sit amet diam et nec quam. Pretium placerat aliquet etiam tristique dolor nisi ullamcorper aenean. Sit ullamcorper nisl diam eget sed imperdiet viverra cursus.',
    //       subTitleTextSub: 'Can’t find Solution?'
    //     },
    //     {
    //       id: 2,
    //       headerTitle: 'How to change my Ticket? 1',
    //       subTitle: 'Solution',
    //       subTitleText:
    //         'Convallis morbi sit amet diam et nec quam. Pretium placerat aliquet etiam tristique dolor nisi ullamcorper aenean. Sit ullamcorper nisl diam eget sed imperdiet viverra cursus.',
    //       subTitleTextSub: 'Can’t find Solution?'
    //     },
    //     {
    //       id: 3,
    //       headerTitle: 'How to change my Ticket? 1',
    //       subTitle: 'Solution',
    //       subTitleText:
    //         'Convallis morbi sit amet diam et nec quam. Pretium placerat aliquet etiam tristique dolor nisi ullamcorper aenean. Sit ullamcorper nisl diam eget sed imperdiet viverra cursus.',
    //       subTitleTextSub: 'Can’t find Solution?'
    //     }
    //   ]
    // },
    // {
    //   label: 'Travel Insurance',
    //   content: [
    //     {
    //       id: 0,
    //       headerTitle: 'How to change my Ticket? 1',
    //       subTitle: 'Solution',
    //       subTitleText:
    //         'Convallis morbi sit amet diam et nec quam. Pretium placerat aliquet etiam tristique dolor nisi ullamcorper aenean. Sit ullamcorper nisl diam eget sed imperdiet viverra cursus.',
    //       subTitleTextSub: 'Can’t find Solution?'
    //     },
    //     {
    //       id: 1,
    //       headerTitle: 'How to change my Ticket? 1',
    //       subTitle: 'Solution',
    //       subTitleText:
    //         'Convallis morbi sit amet diam et nec quam. Pretium placerat aliquet etiam tristique dolor nisi ullamcorper aenean. Sit ullamcorper nisl diam eget sed imperdiet viverra cursus.',
    //       subTitleTextSub: 'Can’t find Solution?'
    //     },
    //     {
    //       id: 2,
    //       headerTitle: 'How to change my Ticket? 1',
    //       subTitle: 'Solution',
    //       subTitleText:
    //         'Convallis morbi sit amet diam et nec quam. Pretium placerat aliquet etiam tristique dolor nisi ullamcorper aenean. Sit ullamcorper nisl diam eget sed imperdiet viverra cursus.',
    //       subTitleTextSub: 'Can’t find Solution?'
    //     },
    //     {
    //       id: 3,
    //       headerTitle: 'How to change my Ticket? 1',
    //       subTitle: 'Solution',
    //       subTitleText:
    //         'Convallis morbi sit amet diam et nec quam. Pretium placerat aliquet etiam tristique dolor nisi ullamcorper aenean. Sit ullamcorper nisl diam eget sed imperdiet viverra cursus.',
    //       subTitleTextSub: 'Can’t find Solution?'
    //     }
    //   ]
    // },
    // {
    //   label: 'Local Tour Guides',
    //   content: [
    //     {
    //       id: 0,
    //       headerTitle: 'How to change my Ticket? 1',
    //       subTitle: 'Solution',
    //       subTitleText:
    //         'Convallis morbi sit amet diam et nec quam. Pretium placerat aliquet etiam tristique dolor nisi ullamcorper aenean. Sit ullamcorper nisl diam eget sed imperdiet viverra cursus.',
    //       subTitleTextSub: 'Can’t find Solution?'
    //     },
    //     {
    //       id: 1,
    //       headerTitle: 'How to change my Ticket? 1',
    //       subTitle: 'Solution',
    //       subTitleText:
    //         'Convallis morbi sit amet diam et nec quam. Pretium placerat aliquet etiam tristique dolor nisi ullamcorper aenean. Sit ullamcorper nisl diam eget sed imperdiet viverra cursus.',
    //       subTitleTextSub: 'Can’t find Solution?'
    //     },
    //     {
    //       id: 2,
    //       headerTitle: 'How to change my Ticket? 1',
    //       subTitle: 'Solution',
    //       subTitleText:
    //         'Convallis morbi sit amet diam et nec quam. Pretium placerat aliquet etiam tristique dolor nisi ullamcorper aenean. Sit ullamcorper nisl diam eget sed imperdiet viverra cursus.',
    //       subTitleTextSub: 'Can’t find Solution?'
    //     },
    //     {
    //       id: 3,
    //       headerTitle: 'How to change my Ticket? 1',
    //       subTitle: 'Solution',
    //       subTitleText:
    //         'Convallis morbi sit amet diam et nec quam. Pretium placerat aliquet etiam tristique dolor nisi ullamcorper aenean. Sit ullamcorper nisl diam eget sed imperdiet viverra cursus.',
    //       subTitleTextSub: 'Can’t find Solution?'
    //     }
    //   ]
    // },
    // {
    //   label: 'Packing List',
    //   content: [
    //     {
    //       id: 0,
    //       headerTitle: 'How to change my Ticket? 1',
    //       subTitle: 'Solution',
    //       subTitleText:
    //         'Convallis morbi sit amet diam et nec quam. Pretium placerat aliquet etiam tristique dolor nisi ullamcorper aenean. Sit ullamcorper nisl diam eget sed imperdiet viverra cursus.',
    //       subTitleTextSub: 'Can’t find Solution?'
    //     },
    //     {
    //       id: 1,
    //       headerTitle: 'How to change my Ticket? 1',
    //       subTitle: 'Solution',
    //       subTitleText:
    //         'Convallis morbi sit amet diam et nec quam. Pretium placerat aliquet etiam tristique dolor nisi ullamcorper aenean. Sit ullamcorper nisl diam eget sed imperdiet viverra cursus.',
    //       subTitleTextSub: 'Can’t find Solution?'
    //     },
    //     {
    //       id: 2,
    //       headerTitle: 'How to change my Ticket? 1',
    //       subTitle: 'Solution',
    //       subTitleText:
    //         'Convallis morbi sit amet diam et nec quam. Pretium placerat aliquet etiam tristique dolor nisi ullamcorper aenean. Sit ullamcorper nisl diam eget sed imperdiet viverra cursus.',
    //       subTitleTextSub: 'Can’t find Solution?'
    //     },
    //     {
    //       id: 3,
    //       headerTitle: 'How to change my Ticket? 1',
    //       subTitle: 'Solution',
    //       subTitleText:
    //         'Convallis morbi sit amet diam et nec quam. Pretium placerat aliquet etiam tristique dolor nisi ullamcorper aenean. Sit ullamcorper nisl diam eget sed imperdiet viverra cursus.',
    //       subTitleTextSub: 'Can’t find Solution?'
    //     }
    //   ]
    // }
  ];

  return (
    <div>
      <div
        style={{
          width: '100%',
          margin: '4%  auto',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Tabs
          style={{ fontFamily: 'Poppins, sans-serif' }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabItems.map((item, index) => (
            <Tab
              key={index}
              label={item.label}
              style={{
                backgroundColor: value === index ? '#31449B' : '#fff',
                color: value === index ? '#fff' : '#101012',
                fontSize: '100%',
                fontWeight: '600',
                padding: '10px 15px',
                boxShadow: '16px 16px 16px 0 #F2F1F2',
                marginLeft: '15px',
                border: '1px solid rgba(0, 0, 0, 0.05)',
                borderRadius: '4px',
                fontFamily: 'Poppins, sans-serif',
              }}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </div>

      <section style={{ width: '100%', margin: '4% auto' }}>
        {tabItems.map((item, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            <CustomerSupportFaq
              content={item?.content}
              isClicked={isClicked}
              setIsClicked={setIsClicked}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0 auto',
              }}
            >
              <Button
                size="small"
                onClick={() => setIsClicked(isClicked && !isClicked)}
                sx={{ textAlign: 'center', color: 'black' }}
                endIcon={
                  <KeyboardDoubleArrowDownIcon sx={{ color: 'black' }} />
                }
              >
                More
              </Button>
            </div>
          </CustomTabPanel>
        ))}
      </section>
    </div>
  );
}
