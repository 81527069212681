import React from 'react';
import './DownloadApp.css';
import './downloadApp.module.css';
import DownloadAppGersStarted from './DownloadAppGersStarted';
import { Helmet } from 'react-helmet';

const allImages = {
  content_image: '../../../Assets/Img/png/get-started-image.png',
  Contents_image: '../../../Assets/Img/png/community-image.png',
  iphone_14_pro1: '../../../Assets/Img/png/iPhone-14-Pro1.png',
  iphone_14_pro2: '../../../Assets/Img/png/iPhone-14-Pro2.png',
  iphone_14_pro3: '../../../Assets/Img/png/iPhone-14-Pro3.png',
  hero_bg1: '../../../Assets/Img/png/hero-bg1.png',
  hero_bg2: '../../../Assets/Img/png/hero-bg2.png',
  hero_image: '../../../Assets/Img/png/download-app-maps.png',
  google_play: '../../../Assets/Img/png/google_play.png',
  app_store: '../../../Assets/Img/png/App_store.png',
  ready_to_explr_iamge: '../../../Assets/Img/png/ready-to-explr-image.png',
  dots_element: '../../../Assets/Img/svg/dots-element-1.svg',
};

export default function DownloadApp() {
  return (
    <>
      <Helmet>
        <title>Download the Explrar App: Your Essential Travel Companion</title>
        <meta
          name="description"
          content="Ready to make travel stress-free? Download the Explrar app to access AI-driven trip itineraries, book flights and hotels, and unlock exclusive AR features on the go. Start your next adventure with Explrar in the palm of your hand!"
        />
      </Helmet>
      {/* Experience a World of Travel at Your Fingertips SECTION START */}
      <section className="parent-container">
        <div className="experience-a-world-of-travel-container">
          <img
            className="experience-a-world-of-travel-hero-bg1"
            src={allImages?.hero_bg1}
            alt="Download app hero bg"
          />
          <img
            className="experience-a-world-of-travel-hero-bg2"
            src={allImages?.hero_bg2}
            alt="Download app hero bg"
          />
        </div>

        <div className="experience-a-world-of-travel-data-container">
          <div>
            <h1 className="experience-a-world-of-travel-data-title-info">
              Experience a World of Travel <br /> at Your Fingertips
            </h1>
            <p className="experience-a-world-of-travel-data-sub-info">
              Whether you’re planning a spontaneous weekend getaway or a grand
              global adventure, the Explrar app is your ultimate travel
              companion. Designed for travelers who value convenience,
              flexibility, and insight, our app puts the power to explore the
              world right in your pocket.
            </p>

            <div style={{ display: 'flex', gap: '10px', marginTop: '40px' }}>
              <div>
                <img
                  src={allImages?.google_play}
                  alt="Download app google_play img"
                />
              </div>
              <div>
                <img
                  src={allImages?.app_store}
                  alt="Download app app_store img"
                />
              </div>
            </div>
          </div>

          <div>
            <img
              src={allImages?.hero_image}
              style={{ height: '619px' }}
              alt="Download app hero bg"
            />
          </div>
        </div>
      </section>
      {/* Experience a World of Travel at Your Fingertips SECTION End */}

      {/* Why Download the Explrar App section start */}
      <section className="download-the-explrar-app-main-section-container">
        <div>
          <img
            className="download-the-explrar-app-dots-elements1"
            src={allImages?.dots_element}
            alt="Download app dots img"
          />
          <img
            className="download-the-explrar-app-dots-elements2"
            src={allImages?.dots_element}
            alt="Download app dots img"
          />
          <img
            className="download-the-explrar-app-dots-elements3"
            src={allImages?.dots_element}
            alt="Download app dots img"
          />
        </div>
        <h1 className="download-the-explrar-app-section-header-title">
          Why Download the{' '}
          <span className="download-the-explrar-app-text-color">Explrar</span>{' '}
          App?
        </h1>
        <div className="download-the-explrar-app-section-info">
          <div>
            <img
              src={allImages?.iphone_14_pro1}
              alt="Download app iphone_14_pro img"
            />
          </div>

          <div>
            <h3 className="download-the-explrar-app-title-text">
              Book Flights and Hotels in one place
            </h3>
            <p className="download-the-explrar-app-sub-title-text">
              Book flights and hotels from one seamless interface. No need{' '}
              <br /> to juggle multiple apps and websites.
            </p>
          </div>
        </div>

        <div className="download-the-explrar-app-section-info">
          <div>
            <h3 className="download-the-explrar-app-title-text">
              Personalized Itineraries
            </h3>
            <p className="download-the-explrar-app-sub-title-text">
              Use our AI-generated trip planner to generate <br /> customized
              itineraries based on your <br /> preferences and past travel
              behavior.
            </p>
          </div>

          <div>
            <img
              src={allImages?.iphone_14_pro2}
              alt="Download app iphone_14_pro img"
            />
          </div>
        </div>

        <div className="download-the-explrar-app-section-info">
          <div>
            <img
              src={allImages?.iphone_14_pro3}
              alt="Download app iphone_14_pro img"
            />
          </div>

          <div>
            <h3 className="download-the-explrar-app-title-text">
              Augmented Reality (AR) Features
            </h3>
            <p className="download-the-explrar-app-sub-title-text">
              Navigate with ease, get information about popular <br />{' '}
              attractions, and live language translation with a point
              functionName: function(arguments) your phone camera with our AR
              Explrar.
            </p>
          </div>
        </div>
      </section>
      {/* Why Download the Explrar App section End */}

      {/* Get Started in Seconds section start  */}
      <section className="get-started-section-all-data-container">
        <div className="get-started-section-container">
          <div>
            <h1 className="get-started-section--heder-title-data">
              Get Started in Seconds
            </h1>
          </div>
          <div className="get-started-section-data-container">
            <div style={{ display: 'flex', gap: '24px' }}>
              <div className="progress-blue"></div>
              <div
                className="total-progress"
                style={{
                  border: '4px solid rgba(49, 68, 155, 0.12)',
                  position: 'relative',
                }}
              ></div>
              <div>
                <DownloadAppGersStarted />
              </div>
            </div>

            <div className="get-started-hero-container">
              <img
                className="get-started-hero"
                src={allImages?.content_image}
                alt="Download app content img"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Get Started in Seconds section End  */}

      {/* Join Our Community Section start */}
      <section className="join-our-community-all-data-section-container">
        <div className="join-our-community-section-container">
          <div>
            <img
              src={allImages?.Contents_image}
              alt="Download app contents img"
            />
          </div>
          <div>
            <h2 className="join-our-community-section-title-text">
              Join Our Community
            </h2>
            <p className="join-our-community-section-sub-title-text">
              By downloading the Explrar app, you’re not just planning a trip;
              you’re joining a community of travelers who cherish the joy of
              discovery. Share your experiences, get inspired by others, and
              explore the world with confidence.
            </p>
          </div>
        </div>
      </section>
      {/* Join Our Community Section End */}

      {/* Ready To Explr section start */}
      <section className="ready-to-explr-main-section-container">
        <div className="ready-to-explr-section-container">
          <div>
            <h2 className="ready-to-explr-section-title-text">
              Ready To Explr?
            </h2>
            <p className="ready-to-explr-sub-title-text">
              Download the Explrar app now and transform the way you travel.
              Adventure is just a tap away!
            </p>

            <div style={{ display: 'flex', gap: '10px', marginTop: '40px' }}>
              <div>
                <img
                  src={allImages?.google_play}
                  alt="Download app google_play img"
                />
              </div>
              <div>
                <img
                  src={allImages?.app_store}
                  alt="Download app app_store img"
                />
              </div>
            </div>
          </div>
          <div>
            <img
              src={allImages?.ready_to_explr_iamge}
              alt="Download app ready to explr img.."
            />
          </div>
        </div>
      </section>
      {/* Ready To Explr section End */}
    </>
  );
}
