import React from 'react';

export default function SharedBlogCard({ blogs, viewBlog }) {
  return (
    <div className="explrar-story-blog-item">
      {blogs.slice(0, viewBlog).map((blog, idx) => {
        return (
          <div className="explrar-story-blog-item-container" key={idx}>
            <img src={blog.img} alt="" />
            <h4>
              {blog.author} • {blog.publishedDate.toDateString()}
            </h4>
            <h1>{blog.title}</h1>
            <p>{blog.content}</p>
            <div className="explrar-story-blog-item-container-tags">
              {blog.tags.map((tag, idx) => (
                <p key={idx}>{tag}</p>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}
