import React, { useEffect, useState } from 'react';

export default function ArExplrarWork({ arExplrarWork }) {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window?.innerHeight;
      const documentHeight =
        document.querySelector('.section-height')?.clientHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const trackLength = documentHeight - windowHeight;
      const scrollPercentage = (scrollTop / trackLength) * 100;
      setScrollPercentage(scrollPercentage);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="section-height ar-explrar-work-container">
      <div
        className="scroll-bar-ar-explrar-work"
        style={{
          width: 14,
          backgroundColor: '#E7E7E7',
          borderRadius: ' 25px',
          position: 'relative',
        }}
      >
        <div
          style={{
            height: `${scrollPercentage * 3}px`,
            width: '15px',
            backgroundColor: '#FDA400',
            transition: 'height 0.5s ease',
            borderBottomLeftRadius: '50px',
            borderBottomRightRadius: '50px',
            position: 'sticky',
            top: '0',
            left: '0px',
          }}
        />
      </div>
      <div className="ar-exploar-work-container">
        <section>
          <div className="ar-exploar-work-ellose-big-icon1">
            <img src="../../Assets/Img/Ellipse 618.svg" alt="" />
          </div>

          <div className="ar-exploar-work-ellose-big-icon2">
            <img src="../../Assets/Img/Ellipse 618.svg" alt="" />
          </div>

          <div className="ar-exploar-work-ellose-small-icon1">
            <img src="../../Assets/Img/Ellipse 624.svg" alt="" />
          </div>

          <div className="ar-exploar-work-ellose-small-icon2">
            <img src="../../Assets/Img/Ellipse 624.svg" alt="" />
          </div>

          <div className="ar-exploar-work-ellose-small-icon3">
            <img src="../../Assets/Img/Ellipse 624.svg" alt="" />
          </div>

          <div className="ar-exploar-work-ellose-small-icon4">
            <img src="../../Assets/Img/Ellipse 624.svg" alt="" />
          </div>
        </section>

        {arExplrarWork.map((work, ind) => (
          <div
            className="ar-exploar-work-sub-container"
            key={ind}
            style={{ zIndex: '100' }}
          >
            <div className="ar-exploar-work-title-container">
              <h2 className="ar-exploar-work-header-title">
                {work.headerTitle}
              </h2>
              <p className="ar-exploar-work-sub-title">{work.headerSubTitle}</p>
            </div>
            <div>
              <img src={work.work_Image} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
