import React from 'react';
import './LatestNewsAbout.css';
const allImages = {
  arrowLeftImage: '../../../Assets/icons/Arrow left.svg',
  arrowRightImage: '../../../Assets/icons/Arrow right.svg',
};
export default function LatestNewsAbout() {
  const blogPageLatestNews = [
    {
      image: '../../../Assets/Img/png/blog-page-image6.png',
      nameAndDate: 'Olivia Rhye • 20 Jan 2024',
      title: 'UX review presentations',
      subTitle:
        'How do you create compelling presentations that wow your colleagues and impress your managers?',
      category: [{ cat1: 'Design', cat2: 'Research', cat3: 'Presentation' }],
    },
    {
      image: '../../../Assets/Img/png/blog-page-image.png',
      nameAndDate: 'Phoenix Baker • 19 Jan 2024',
      title: 'Migrating to Linear 101',
      subTitle:
        'Linear helps streamline software projects, sprints, tasks, and bug tracking. Here’s how to get started.',
      category: [{ cat1: 'Product', cat2: 'Tools', cat3: 'SaaS' }],
    },
    {
      image: '../../../Assets/Img/png/blog-page-image1.png',
      nameAndDate: 'Lana Steiner • 18 Jan 2024',
      title: 'Building your API stack',
      subTitle:
        'How do you create compelling presentations that wow your colleagues and impress your managers?',
      category: [{ cat1: 'Software Development', cat2: 'Tools' }],
    },
    {
      image: '../../../Assets/Img/png/blog-page-image7.png',
      nameAndDate: 'Alec Whitten • 17 Jan 2024',
      title: 'Bill Walsh leadership ',
      subTitle:
        'Linear helps streamline software projects, sprints, tasks, and bug tracking. Here’s how to get started.',
      category: [{ cat1: 'Leadership', cat2: 'Management', cat3: 'SaaS' }],
    },
  ];
  return (
    <section>
      <div className="blog-page-latest-news-about-container">
        <div style={{ marginBottom: '64px' }}>
          <h2>Latest news about Explrar</h2>
          <p>
            The latest our company news, technologies, and resources from our
            team.
          </p>
        </div>
        <div className="blog-page-latest-news-container">
          {blogPageLatestNews.map((news, ind) => (
            <div key={ind}>
              <img src={news?.image} alt="" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '6px',
                  marginTop: '20px',
                }}
              >
                <p className="blog-page-card-nameAndDate">
                  {news?.nameAndDate}
                </p>
                <h2 className="blog-page-card-title">{news?.title}</h2>
                <p className="blog-page-card-subtitle">{news?.subTitle}</p>
              </div>
              <div>
                {news.category.map((cat, ind) => (
                  <div
                    style={{ display: 'flex', gap: '12px', marginTop: '24px' }}
                    key={ind}
                  >
                    <p className="blog-page-card-category-btn1">{cat?.cat1}</p>
                    <p className="blog-page-card-category-btn2">{cat?.cat2}</p>
                    <p className="blog-page-card-category-btn3">{cat?.cat3}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div
          style={{
            display: 'flex',
            gap: '32px',
            alignItems: 'center',
            marginTop: '40px',
          }}
        >
          <button
            style={{
              border: '1px solid #EAECF0',
              backgroundColor: '#FBFBFD',
              padding: '24px',
              borderRadius: '50%',
            }}
          >
            <img
              style={{
                width: '40px',
                height: '40px',
              }}
              src={allImages.arrowLeftImage}
              alt=""
            />
          </button>

          <button
            style={{
              border: '1px solid #EAECF0',
              backgroundColor: '#FBFBFD',
              padding: '24px',
              borderRadius: '50%',
            }}
          >
            <img
              style={{
                width: '40px',
                height: '40px',
              }}
              src={allImages.arrowRightImage}
              alt=""
            />
          </button>
        </div>
      </div>
    </section>
  );
}
