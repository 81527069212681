import { Alert, Stack } from '@mui/material';
import React, { useState } from 'react';
import { Triangle } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from 'react-top-loading-bar';
import {
  userEmailUpdate,
  userEmailUpdateCancel,
  userEmailUpdateOTP,
  userEmailUpdateOTPCancel,
  userEmailUpdateOTPFailure,
  userResetPasswordScreenCancel,
} from '../../redux/userState';
import { useNavigate } from 'react-router-dom';
import UpdationSuccess from '../popup/UpdationSuccess';
import ResetOtpVerify from '../security/ResetOtpVerify';

export default function UpdateEmails() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');

  function handleSubmit(e) {
    e.preventDefault();

    if (!email) {
      return dispatch(
        userEmailUpdateOTPFailure({
          status: false,
          isEmailUpdated: false,
          message:
            'The email field cannot be empty. Please enter your email address to proceed',
        })
      );
    }
    const json = {
      email: email.toLowerCase(),
      access_token: localStorage.getItem('access_token'),
      type: 'updateEmail',
    };

    dispatch(userEmailUpdateOTP(json));
  }

  function returnToHomeHandler() {
    dispatch(userEmailUpdateCancel());
    navigate('/');
  }

  return (
    <div className="min-vh-100 d-flex flex-column position-relative">
      <LoadingBar color="#FDA400" progress={user.progress} />
      {user?.isEmailUpdateOTPSent && user?.userOTP?.isEmailUpdated && (
        <UpdationSuccess onClick={returnToHomeHandler} type="email" />
      )}
      {!user?.userOTP?.isEmailUpdated && (
        <>
          <div className="d-flex gap-4 align-items-center justify-content-start">
            <div
              className={`${user?.isLoading ? 'disabled cursor-not-allowed' : 'cursor-pointer '}`}
              onClick={() => {
                if (!user?.isLoading) {
                  dispatch(userEmailUpdateOTPCancel());
                  dispatch(userResetPasswordScreenCancel());
                  navigate(-1);
                }
              }}
            >
              <img src="/Assets/Img/svg/left-forward_icon.svg" alt="Key_icon" />
            </div>
            <p className="fs-22 fw-600 color-blue-black">{`${user?.isEmailUpdateOTPSent ? 'Verify OTP' : 'Enter New Email Address'}`}</p>
          </div>
          {user.isError && user.error?.message ? (
            <Stack className="fs_lg fw_xtr_bold ff_inter color_blue_black text-center mt-4">
              <Alert severity="error">{user.error.message}</Alert>
            </Stack>
          ) : (
            ''
          )}
          {user?.isEmailUpdateOTPSent && !user?.userOTP?.isEmailUpdated && (
            <ResetOtpVerify
              dispatchFn={userEmailUpdate}
              email={email.toLowerCase()}
              type="updateEmail"
            />
          )}

          {!user?.isEmailUpdateOTPSent && !user?.userOTP?.isEmailUpdated && (
            <>
              <p className="confirm-mail-p mt-5" style={{ fontSize: '16px' }}>
                Please enter a new email address for your account.
              </p>
              <p className="confirm-mail-p mt-3" style={{ fontSize: '16px' }}>
                When it is confirmed, you’ll need to use the new email to sign
                in to your Explrar account.
              </p>
              <form action="" onSubmit={handleSubmit}>
                <div className="d-flex flex-column mt-4 pt-2">
                  <div
                    style={
                      user.isError && user.error.isMailValid
                        ? { border: '1px solid #E71D36' }
                        : {}
                    }
                    className="d-flex align-items-center input_border mt-3"
                  >
                    <div className="d-flex align-items-center w-100">
                      <img
                        src="/Assets/Img/svg/Mail_icon.svg"
                        alt="Mail_icon"
                      />
                      <input
                        className="fs_sm fw_normal ff_inter w-100 ps-2"
                        type="email"
                        placeholder="Enter New Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-4">
                  <button
                    className="login_btn w-100 fs_sm fw_bold ff_inter color_white"
                    disabled={user.isLoading}
                  >
                    {user.isLoading ? (
                      <Triangle
                        height="30"
                        width="30"
                        color="#FDA400"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{ justifyContent: 'space-around' }}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      'Change Email'
                    )}
                  </button>
                </div>
              </form>
            </>
          )}
        </>
      )}
    </div>
  );
}
